import resolveImage from "./resolveImage";
import resolveBreaks from "./resolveBreaks";

const resolvePB = pb => {
  if (pb && pb.length) {
    return pb.map(block => {
      const { type, uid } = block;
      switch (type) {
        case "copy":
          return { uid, type, copy: block.copy };
        case "image":
          return {
            uid,
            type,
            image: resolveImage(block.image),
            caption: block.caption,
          };
        case "quote":
          return {
            uid,
            type,
            quote: block.quoteContent,
            author: block.author,
            image: resolveImage(block.authorImage),
          };
        case "video":
          return {
            uid,
            type,
            videoUrl: block.videoUrl,
            caption: block.caption,
          };
        case "scrollingContent":
          return {
            uid,
            type,
            processes: block.cards.map(process => {
              return {
                heading: resolveBreaks(process.heading),
                description: process.description,
                processInformation: process?.processInformation?.map(info => {
                  return {
                    label: info.label,
                    content: resolveBreaks(info.content),
                  };
                }),
                color: process.color,
              };
            }),
          };
        case "imageWithCopy":
          return {
            uid,
            type,
            heading: block.heading,
            subhead: block.subhead,
            description: block.copy,
            link: block.linkField,
            image: resolveImage(block.image),
            right: block.imageLocation,
          };
        case "stickyContent":
          return {
            uid,
            type,
            heading: block.heading,
            image: resolveImage(block.image),
            copy: block.stickyCopy.map(copy => {
              return {
                heading: copy.heading,
                copy: copy.copy,
                items: copy.items.map(item => {
                  return {
                    label: item.label,
                    content: resolveBreaks(item.content),
                    full: item.full,
                  }
                }),
              };
            }),
          };
        case "selectProjects":
          return {
            uid,
            type,
            heading: block.heading,
            subhead: block.subhead,
            projects: block.projects.map(project => {
              return {
                title: project.title,
                description: project.heroDescription,
                image: resolveImage(project.heroBackgroundImage),
                url: project.url,
                categories: project.projectCategories.map( category => {
                  return {
                    title: category.title,
                    slug: category.slug,
                  };
                }),
              };
            }),
          };
        case "fullWidth":
          return {
            uid,
            type,
            heading: block.heading,
            subhead: block.subhead,
            link: block.linkField,
            image: resolveImage(block.backgroundImage),
          };
        case "sideHeader":
          return {
            uid,
            type,
            heading: block.heading,
            subhead: block.description,
            color: block.knockoutColor,
          };
        case "team":
          return {
            uid,
            type,
            heading: block.heading,
            description: block.subhead,
            team: block.team.map(member => {
              return {
                name: member.teamMemberName,
                title: member.jobTitle,
                headshot: resolveImage(member.headshot),
                large: member.large,
              };
            }),
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolvePB;
