import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { AppLink } from "@base";
import { Image, Text, Button, Knockout } from "@atoms";

const ProjectGridCard = ({
  title,
  description,
  categories,
  url,
  image,
  size,
  notFull,
}) => {
  return (
    <div
      className={classNames("w-full relative",
      { "max-w-unset lg:max-w-3xl col-span-full lg:col-span-4 lg:row-span-2" : size === "lg" },
      { "max-w-unset lg:max-w-md col-span-full sm:col-span-3 lg:col-span-2 lg:row-span-1" : size === "sm" },
    )}>
      <AppLink to={url}>
        <div className={classNames("w-full bg-blue relative z-10",
          {"h-96" : size === "lg" },
          {"h-48" : size === "sm" },
          )}>
          <div className="absolute inset-0 w-full h-full">
            <Image {...image} fill />
          </div>
        </div>

        <div className="mt-2 bg-black py-4 space-y-6 relative">
          <div>
            <Text variant="lg">
              {title}
            </Text>

            <ul className="mt-4">
              {categories.map((category, i) => {
                return (
                  <li className="inline-block mr-4 mb-2" key={i}>
                    <Text
                      variant="label"
                      className="text-blue"
                    >
                      <span>
                        {category.title}
                        {
                          i !== (categories.length - 1) ? 
                          "," : 
                          "" 
                        }
                      </span>
                    </Text>
                  </li>
                )
              })}
            </ul>
          </div>

          {size === "lg" && (
            <div>
              <Text
                variant="sm"
              >
                {description}
              </Text>
            </div>
          )}

          <div>
            <Button
              border={false}
              arrow={false}
              underlineColor="blue"
            >
              <Text
                variant="label"
              >
                Dig in
              </Text>
            </Button>
          </div>
        </div>
      </AppLink>

      {size === "lg" && (
        <div className={classNames("hidden lg:block",
          { "relative mt-48" : notFull },
          { "absolute bottom-0 left-0" : !notFull },
        )}>
          <Button to="/projects">
            <Text>
              All the Projects
            </Text>
          </Button>
        </div>
      )}
    </div>
  );
};

ProjectGridCard.defaultProps = {};

ProjectGridCard.propTypes = {};

export default ProjectGridCard;
