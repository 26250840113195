import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const HeroSimple = ({ heading, label }) => {
  return (
    <div className="pt-40 pb-14">
      <Container
        variant="xl"
        className="text-center relative z-10"
      >
        <div className="space-y-4">
          <Knockout
            variant="label-lg"
            knockoutColor="blue"
            textColor="black"
          >
            {label}
          </Knockout>
          <Text
            variant="h2"
            className="text-white"
          >
            {heading}
          </Text>
        </div>
      </Container>
    </div>
  );
};

HeroSimple.defaultProps = {};

HeroSimple.propTypes = {};

export default HeroSimple;
