import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Knockout } from "@atoms";

const HeroContact = ({ heading, subhead, color }) => {
  return (
    <Container
      variant="xl"
      className="relative z-10"
    >
      <div className="relative z-10 pt-32 pb-12 md:py-32 mx-auto">
        <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-8 md:space-x-24 space-y-4 sm:space-y-0 ">
          <div className="flex-shrink-0">
            <Knockout
              variant="h3"
              textColor="black"
              knockoutColor={color || "bright-pink"}
            >
              {heading}
            </Knockout>
          </div>
          
          <div className="max-w-xl w-full">
            <Text
              variant="lg"
            >
              {subhead}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  );
};

HeroContact.defaultProps = {};

HeroContact.propTypes = {};

export default HeroContact;
