import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { SidebarPostCard } from "@molecules";

const SidebarRelatedArticles = ({ relatedArticles, relatedCaseStudies }) => {
  return (
    <>
      {relatedArticles?.length > 0 && (
        <div className="mb-8">
          <Text variant="lg" className="mb-12">Related Articles</Text>
          <div className="gap-12 flex flex-col sm:flex-row md:flex-col">
            {relatedArticles?.map((post, i) => {
              return (
                <SidebarPostCard {...post} key={i} />
              )
            })}
          </div>
        </div>
      )}
      {relatedCaseStudies?.length > 0 && (
        <div className="mb-8">
          <Text variant="lg" className="mb-12">Related Case Studies</Text>
          <div className="gap-12 flex flex-col sm:flex-row md:flex-col">
            {relatedCaseStudies?.map((post, i) => {
              return (
                <SidebarPostCard {...post} key={i} />
              )
            })}
          </div>
        </div>
      )}
    </>
  );
};

SidebarRelatedArticles.defaultProps = {};

SidebarRelatedArticles.propTypes = {};

export default SidebarRelatedArticles;