import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@atoms";

const CbCopy = ({ copy, centered }) => {
  return (
    <Text
      variant="sm"
      className={classNames(
        "rich-text",
        { "text-center mx-auto": centered },
      )}
    >
      {copy}
    </Text>
  );
};

CbCopy.defaultProps = {};

CbCopy.propTypes = {};

export default CbCopy;