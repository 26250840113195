import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Text, Icon } from "@atoms";

const QuotesHome = ({ quotes }) => {
  const [activeQuote, setActiveQuote] = useState(0);
  return (
    <div className="bg-bright-yellow pt-16 md:pt-24 pb-12 overflow-hidden">
      <Container
        variant="xl"
        className="flex flex-col"
      >
        <div className="mb-0 md:mb-12">
          <Quote {...quotes[activeQuote]} />
        </div>

        <div className="flex flex-row justify-center w-full">
          <div className="flex flex-row mt-8 space-x-6 md:space-x-16">
            {quotes?.map((quote, i) => {
              return (
                <div
                  className={`opacity-35 hover:opacity-100 
                  ${activeQuote === i ? "opacity-100" : ""} `}
                  key={`company-${i}`}
                >
                  <button
                    type="button" 
                    onClick={() => {
                      setActiveQuote(i);
                    }}
                    className="block"
                  >
                    <div className="w-20 md:w-32 h-12 max-w-md mx-auto md:mx-0">
                      <Image 
                        {...quote.companyImage}
                        placeholder={false}
                        fitHeight
                      />
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

QuotesHome.defaultProps = {};

QuotesHome.propTypes = {};

export default QuotesHome;