import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container } from "@atoms";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ManifestoLargeText = ({ heading, color, keyword, dontSetFooter }) => {
  const container = useRef();
  const textContainer = useRef();
  const [{ manifesto }, dispatch] = useAppState();

  const split = word => {
    return word.split(" ").map(word => `<span class="relative">${word}</span>`).join(" ");
  };

  useEffect(() => {
    if (container.current && textContainer.current) {
      const tl = gsap.timeline({ paused: true })
      gsap.utils.toArray(textContainer.current.children[0].children).forEach((word, i) => {
        tl.fromTo(word, 
          { 
            opacity: 0,
            top: "1rem",
          },
          { opacity: 1, 
            top: 0,
            duration: .8,
            ease: "expo.out",
          },
          `<+${i * 0.01}`)
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top center",
        once: true,
        onEnter: () => {
          tl.restart();
        }
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        invalidateOnRefresh: true,
        onEnterBack: () => {
          if (dontSetFooter) {
            dispatch({
              type: "setFooterColor",
              footerColor: "black",
            });
          }
        },
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top bottom",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "setFooterColor",
            footerColor: color,
          });
        },
      });
    }
  }, []);

  return (
    <div ref={container} className="py-12 lg:py-20">
      <Container
        variant="xl"
        className="flex items-center py-24"
        
      >
        <div className="w-full" ref={textContainer}>
          <Text
            variant="manifesto-h1"
            className={classNames("manifest-large-text",
              { "text-black": color === "black" },
              { "text-blue": color === "blue" },
              { "text-blue-bright": color === "bright-blue" },
              { "text-bright-orange": color === "bright-orange" },
              { "text-bright-yellow": color === "bright-yellow" },
              { "text-bright-pink": color === "bright-pink" },
            )}
          >
            {split(heading)}
          </Text>
        </div>
      </Container>
    </div>
  );
};

ManifestoLargeText.defaultProps = {};

ManifestoLargeText.propTypes = {};

export default ManifestoLargeText;
