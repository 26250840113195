import React from "react";
import propTypes from "prop-types";
import { Container, ButtonAlt, Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { FooterQuery } from "@staticQueries";
import classNames from "classnames";
import { useAppState } from "@state/state";

const Footer = () => {
  const { nav } = FooterQuery();
  return (
    <footer className="bg-blue-bright tracking-1 pt-12 overflow-hdden w-screen">
      <div className="flex items-center">
        <div className="w-full px-2 md:px-5 pb-12">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0">
            <div className="px-3 flex space-x-4">
              <span id="mynt-tagline" className="invisible h-0 w-0">
                Be electric
              </span>
              <Icon
                name="electric" 
                className="h-8 text-black"
                fitHeight 
                aria-hidden="true" 
                aria-labelledby="mynt-tagline"
              />
              <Icon 
                name="tagline" 
                className="h-8 text-black" 
                fitHeight
                aria-hidden="true" 
                aria-labelledby="mynt-tagline"
              />
            </div>
            <nav className="px-3 flex justify-center items-center">
              <ul className="flex flex-wrap lg:-mx-4 -mx-3">
                {nav.map((link, i) => {
                  return (
                    <li 
                      className="px-2 sm:px-4 lg:px-5"
                      key={`${link.text}-${i}`}
                    >
                      <AppLink
                        activeClassName=""
                        className=""
                        to={link.url}
                      >
                        <Text
                            variant="label-lg"
                            className="text-black"
                          >
                          {link.text}
                        </Text>
                      </AppLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Icon
        name="footerLogo"
        className="text-black w-full -mb-1 relative top-1 bg-blue-bright"
        aria-hidden="true"
      />
    </footer>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
