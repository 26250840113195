import React from "react";
import classNames from "classnames";
import propTypes from "prop-types";

const CornerMask = ({ flip, flipY, flipX, bottom }) => {
  return (
    <div className={classNames("absolute z-10 text-black w-80 md:w-96 lg:w-mask",
      { "inset-0" : !flip && !flipY },
      { "flip-x right-0 top-0" : flip },
      { "flip-y" : flipY },
      { "flip-x" : flipX },
      { "right-0 -bottom-px" : bottom },
    )}>
      <svg 
        className="w-full"
        viewBox="0 0 587 417"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0V416.457L586.222 0H0Z" fill="#070707"/>
      </svg>
    </div> 
  );
};

CornerMask.defaultProps = {};

CornerMask.propTypes = {};

export default CornerMask;
