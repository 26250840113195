import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Knockout, Icon } from "@atoms";
import { AppLink } from "@base";
import moment from "moment";

const PostCard = ({
  title, 
  description, 
  category, 
  link, 
  image, 
  size,
  author,
  date,
  nudge,
}) => {
  let color;
  let headerSize;
  let labelSize;

  switch (category) {
    case "news":
      color = "bright-blue";
      break;
    case "opinion":
      color = "blue";
      break;
    default:
      color = "blue";
      break;
  }

  switch (size) {
    case "lg":
      headerSize = "xl";
      labelSize = "label";
      break;
    case "sm":
      headerSize = "sm";
      labelSize = "label-sm";
      break;
    default:
      headerSize = "body";
      labelSize = "label-lg";
      break;
  }

  return (
    <AppLink 
      to={link}
      className={classNames(
        "w-full no-underline underline-1-transparent group",
        { "max-w-lg" : !size },
        { "col-span-full md:col-span-2 row-span-2 max-w-unset md:max-w-2xl" : size === "lg" },
        { "max-w-unset md:max-w-sm col-span-full md:col-span-1 row-span-1 place-self-start" : size === "sm" },
        { "col-start-1 md:col-start-2" : nudge }
    )}>      
      <div className={classNames(
        "w-full bg-blue relative z-10 overflow-hidden",
        { "h-60" : !size },
        { "h-96" : size === "lg" },
        { "h-48" : size === "sm" },
      )}>
        <div className="absolute inset-0 w-full h-full transform transition-transform duration-700 ease-in-out group-hover:scale-105">
          <Image {...image} fill />
        </div>
      </div>

      <div className={classNames(
        "bg-black relative -top-8 z-20",
        { "mr-8 p-4 space-y-6" : size === "lg" || !size },
        { "py-4 space-y-4" : size === "sm" },
      )}>
        <div>
          <Knockout
            variant={labelSize}
            textColor="black"
            knockoutColor={color} 
          >
            {category}
          </Knockout>
        </div>

        <div>
          <AppLink
            to={link}
            className="no-underline underline-1-transparent"
          >
            <Text
              variant={headerSize}
            >
              {title}
            </Text>
          </AppLink>
        </div>

        {date && author && (
          <div>
            <Text
              variant="xs"
              className="text-grey"
            >
              <span>{author} &ndash; {moment(date).format("MM/DD/YY")}</span>
            </Text>
          </div>
        )}

        {description && size !== "sm" && (
          <div>
            <Text
              variant="xs"
            >
              {description}
            </Text>
          </div>
        )}

        {!date && !author &&(
          <div>
            <Button
              border={false}
              arrow={false}
              underlineColor={color} 
            >
              <Text
                variant="label"
              >
                Read on
              </Text>
            </Button>
          </div>
        )}

        {date && author &&(
          <div>
            <Button
              border={false}
              arrow={false}
              underlineColor="transparent" 
            >
              <Icon
                name="inverseArrow"
                className={classNames(
                  "w-4 transform group-hover:translate-x-1 transition-all duration-300 delay-150",
                  { "text-blue-bright" : color === "bright-blue" },
                  { "text-blue" : color === "blue" },
                  { "text-bright-yellow" : color === "bright-yellow" },
                  { "text-bright-orange" : color === "bright-orange" },
                  { "text-bright-pink" : color === "bright-pink" },
                )}
              />
            </Button>
          </div>
        )}
      </div>

    </AppLink>
  );
};

PostCard.defaultProps = {};

PostCard.propTypes = {};

export default PostCard;
