import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image } from "@atoms";

const CbImage = ({ image, caption, fluid }) => {
  return (
    <div
      className={classNames(
        "flex my-8",
        { "mb-16" : !!caption?.length },
        { "max-w-lg" : !fluid },
      )}
    >
      <Image {...image} caption={caption} />
    </div>
  );
};

CbImage.defaultProps = {};

CbImage.propTypes = {};

export default CbImage;
