import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Icon, Text } from "@atoms";

gsap.registerPlugin(ScrollTrigger);

const ScrollingContainer = ({ children, className: _className }) => {
  const container = useRef();
  const transform = useRef();

  useEffect(() => {
    if (container.current && transform.current) {
      const spacerHeight = container.current.parentNode.clientHeight;
      const scroll = children.length > 3 ? -1.5 * spacerHeight : -.095 * spacerHeight;

      ScrollTrigger.matchMedia({
        "(min-width: 1200px": () => {
          gsap.to(transform.current, { 
            xPercent: -100, 
            x: () => scroll,
            ease: "linear",
            scrollTrigger: {
              trigger: container.current,
              start: "center center",
              end: () => `+=100%`,
              scrub: 0.125,
              pin: true,
              anticipatePin: 1,
              invalidateOnRefresh: true,
            }
          });
        },
      });
    }
  }, []);

  return (
    <div className="relative w-full overflow-x-scroll lg:overflow-x-visible overflow-y-visible pb-2">
      <div 
        ref={container}
        className="w-full sticky"
      >
        <div 
          className="w-full transform"
          ref={transform}
        >
          <div className="block lg:hidden mb-4 relative h-full top-6">
            <div className="transform -rotate-90 origin-top-left z-20 flex flex-row absolute top-24">
              <Text variant="label-lg">
                Scroll
                <Icon name="inverseArrow" className="ml-2 w-4 transform rotate-90" />
              </Text>
            </div>
          </div>
          <div className={`flex flex-1 space-x-20 flex-nowrap justify-start items-stretch ml-12 lg:ml-0 ${_className}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

ScrollingContainer.defaultProps = {};

ScrollingContainer.propTypes = {};

export default ScrollingContainer;