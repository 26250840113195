import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";

const ManifestoOverlay = ({ value }) => {
  const progress = useRef();
  const [{ manifesto }, dispatch] = useAppState();
  const {
    color,
    header,
    showProgress,
    footer,
    footerColor,
    showHeaderText,
  } = manifesto;

  useEffect(() => {
    if (progress.current) {
      gsap.to(progress.current, {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.125 },
      });
    }
  }, []);
  useEffect(() => {}, [color, header, footer, footerColor, showHeaderText]);

  return (
    <>
      <div className="w-full p-2 pt-4 md:p-6 fixed z-40">
        <progress
          max="100"
          min="0"
          value="0"
          id="scrollProgress"
          className={classNames("progress absolute top-0 inset-x-0 w-full transition-opacity transition-colors duration-100",
            { "progress-black" : color === "black" || !color },
            { "progress-blue" : color === "blue" },
            { "progress-bright-blue" : color === "bright-blue" },
            { "progress-bright-pink" : color === "bright-pink" },
            { "progress-bright-orange" : color === "bright-orange" },
            { "progress-bright-yellow" : color === "bright-yellow" },
            { "opacity-100" : showProgress },
            { "pointer-events-none opacity-0 " : !showProgress },
          )}
          ref={progress}
        />

        <div className="w-full flex justify-between">
          <div>
            <Text
              variant="xs"
              className={classNames("transition-colors duration-100",
                { "text-white" : !color },
                { "text-black" : color === "black" },
                { "text-blue" : color === "blue" },
                { "text-blue-bright" : color === "bright-blue" },
                { "text-bright-pink" : color === "bright-pink" },
                { "text-bright-orange" : color === "bright-orange" },
                { "text-bright-yellow" : color === "bright-yellow" },
                { "opacity-0 pointer-events-none" : !showHeaderText },
              )}
            >
              {header}
            </Text>
          </div>

          <AppLink to="/"
          className={classNames("transition-opacity transition-colors duration-100",
            { "pointer-events-none opacity-0" : !showHeaderText },
          )}>
            <Icon
              name="logomark"
              className={classNames("",
                { "text-black" : color === "black" || !color },
                { "text-blue" : color === "blue" },
                { "text-blue-bright" : color === "bright-blue" },
                { "text-bright-pink" : color === "bright-pink" },
                { "text-bright-orange" : color === "bright-orange" },
                { "text-bright-yellow" : color === "bright-yellow" },
              )}
            />
          </AppLink>
        </div>
      </div>

      <div
        className={classNames("flex-shrink-0 p-6 w-full fixed z-40 inset-x-0 bottom-0 w-full flex justify-between transition-opacity opacity-0",
        { "opacity-100" : showProgress },
        { "pointer-events-none" : !showProgress },
        )}
      >
        <div className="flex items-center space-x-2">
          <span
            className={classNames("flex-shrink-0 transition-colors",
              { "text-black" : footerColor === "black" || !footerColor },
              { "text-blue" : footerColor === "blue" },
              { "text-blue-bright" : footerColor === "bright-blue" },
              { "text-bright-pink" : footerColor === "bright-pink" },
              { "text-bright-orange" : footerColor === "bright-orange" },
              { "text-bright-yellow" : footerColor === "bright-yellow" },
            )}
          >
            The Future is
          </span>

          <span className="bg-white h-0.5 w-20 block"></span>

          <span
            className={classNames("flex-shrink-0 transition-colors",
              { "text-black" : footerColor === "black" || !footerColor },
              { "text-blue" : footerColor === "blue" },
              { "text-blue-bright" : footerColor === "bright-blue" },
              { "text-bright-pink" : footerColor === "bright-pink" },
              { "text-bright-orange" : footerColor === "bright-orange" },
              { "text-bright-yellow" : footerColor === "bright-yellow" },
            )}
          >
            {footer}
          </span>
        </div>

        <div className="items-center align-middle hidden md:flex">
          <Icon
            name="electric"
            className={classNames("w-3 mr-1 transition-colors",
              { "text-black" : footerColor === "black" || !footerColor },
              { "text-blue" : footerColor === "blue" },
              { "text-blue-bright" : footerColor === "bright-blue" },
              { "text-bright-pink" : footerColor === "bright-pink" },
              { "text-bright-orange" : footerColor === "bright-orange" },
              { "text-bright-yellow" : footerColor === "bright-yellow" },
            )}
          />
          <Text
            className={classNames("font-antarctican uppercase tracking-0.5 text-sm font-semibold -mt-0.5 transition-colors",
              { "text-black" : footerColor === "black" || !footerColor },
              { "text-blue" : footerColor === "blue" },
              { "text-blue-bright" : footerColor === "bright-blue" },
              { "text-bright-pink" : footerColor === "bright-pink" },
              { "text-bright-orange" : footerColor === "bright-orange" },
              { "text-bright-yellow" : footerColor === "bright-yellow" },
            )}
          >
            Be electric
          </Text>
        </div>
      </div>
    </>
  );
};

ManifestoOverlay.defaultProps = {};
ManifestoOverlay.propTypes = {};

export default ManifestoOverlay;
