import React, { useState } from "react";
import propTypes from "prop-types";
import { Container, Image, Button, Text, Knockout } from "@atoms";
import { AnnouncementCard } from "@molecules";

const MediaAnnouncements = ({ announcements }) => {
  const left = announcements?.filter((a, i) => (i % 5 === 0 || i % 5 === 3) && !a.special);
  const middle = announcements?.filter((a, i) => i % 5 === 1 || a.special);
  const right = announcements?.filter((a, i) => (i % 5 === 2 || i % 5 === 4) && !a.special);
  return (
    <Container variant="2xl">
      <div className="hidden md:grid grid-cols-4 gap-6">
        <div className="max-w-unset md:max-w-sm col-span-full md:col-span-1 row-span-1">
          {left?.map((announcement, i) => {
            return (
              <AnnouncementCard {...announcement} size="sm" key={i} />
            );
          })}
        </div>
        <div className="col-span-full md:col-span-2 row-span-2 max-w-unset md:max-w-2xl">
          {middle?.map((announcement, i) => {
            return (
              <AnnouncementCard {...announcement} size="lg" key={i} />
            );
          })}
        </div>
        <div className="max-w-unset md:max-w-sm col-span-full md:col-span-1 row-span-1">
          {right?.map((announcement, i) => {
            return (
              <AnnouncementCard {...announcement} size="sm" key={i} />
            );
          })}
        </div>
      </div>
      <div className="grid md:hidden grid-cols-1 sm:grid-cols-2 gap-6">
        {announcements?.map((announcement, i) => {
          return (
            <AnnouncementCard {...announcement} size="sm" key={i} />
          );
        })}
      </div>
    </Container>
  );
};

MediaAnnouncements.defaultProps = {};

MediaAnnouncements.propTypes = {};

export default MediaAnnouncements;
