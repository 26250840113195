import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container } from "@atoms";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ManifestoTwoColumns = ({ leftColumn, rightColumn, keyword }) => {
  const container = useRef();
  const [{ manifesto }, dispatch] = useAppState();
  const headingText = `Part 1: The New Energy ${keyword}`;

  useEffect(() => {
    if (container.current) {
      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "setColor",
            color: "blue",
          });
          dispatch({
            type: "setPartHeader",
            header: headingText,
          });
        },
        onEnterBack: () => {
          dispatch({
            type: "setColor",
            color: "blue",
          });
          dispatch({
            type: "setPartHeader",
            header: headingText,
          });
        },
      });
    }
  }, []);

  return (
    <div ref={container} className="py-8">
      <Container
        variant="2xl"
        className="min-h-screen flex items-center"
      >
        <div className="w-full">
          <div className="flex flex-col md:flex-row justify-around space-y-8 md:space-y-0">
            <div className="max-w-lg w-full">
              <Text
                variant="sm"
                className="manifesto-text manifesto-blue"
              >
                {leftColumn}
              </Text>
            </div>
            <div className="max-w-lg w-full">
              <Text
                variant="sm"
                className="manifesto-text manifesto-blue"
              >
                {rightColumn}
              </Text>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

ManifestoTwoColumns.defaultProps = {};

ManifestoTwoColumns.propTypes = {};

export default ManifestoTwoColumns;
