import resolveImage from "./resolveImage";
import resolvePB from "./resolvePB";
import resolveMeta from "./resolveMeta";

const resolvePage = page => {
  if (page) {
    const {
      url,
      uid,
      slug,
      title,
      metaTitle,
      metaImage,
      metaDescription,
      heroHeading,
      heroDescription,
      heroBackgroundImage,
      pageBuilder,
    } = { ...page };

    return {
      url,
      uid,
      slug,
      title,
      meta: resolveMeta({
        title,
        metaTitle,
        metaImage,
        metaDescription,
        slug,
        url,
      }),
      hero: {
        heading: heroHeading,
        description: heroDescription,
        image: resolveImage(heroBackgroundImage),
      },
      pageBuilder: resolvePB(pageBuilder),
    };
  }
  return null;
};

export default resolvePage;
