import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          linkList {
            ... on Craft_linkList_link_BlockType {
              linkObject {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query HeaderQuery {
  craft {
    globalSet(handle: "header") {
      ... on Craft_header_GlobalSet {
        linkList {
          ... on Craft_linkList_link_BlockType {
            linkObject {
              url
              text
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const { linkList } = craft.globalSet;
  return {
    nav: linkList.map(link => {
      const { url, text } = link.linkObject;
      return {
        url,
        text,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
