import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Image, CornerMask } from "@atoms";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useAppState } from "@state/state";

gsap.registerPlugin(ScrollTrigger);

const ManifestoHero = ({ heading, keyword, image, first, last, index }) => {
  const reveal = useRef();
  const container = useRef();
  const leftMask = useRef();
  const rightMask = useRef();
  const imageContainer = useRef();
  const textContainer = useRef();
  const [{ manifesto }, dispatch] = useAppState();
  const { introPlayed } = manifesto;
  const partText = `Part ${index + 1}`;
  const headingText = `${partText}: ${heading} ${keyword}`;

  useEffect(() => {
    if (container.current &&
      leftMask.current &&
      rightMask.current &&
      imageContainer.current &&
      textContainer.current)
    {
      reveal.current = gsap.timeline({ paused: true })
        .fromTo(
          imageContainer.current,
          {
            scale: 1.05,
          },
          {
            scale: 1,
            duration: 1.125,
            ease: "expo.out",
          })
        .fromTo(
          leftMask.current,
          { 
            xPercent: -50,
          },
          { 
            xPercent: 0,
            duration: 0.65,
            ease: "power4.Out",
          }, "<=0.4")
        .fromTo(
          rightMask.current,
          { 
            xPercent: 50,
          },
          { 
            xPercent: 0,
            duration: 0.65,
            ease: "power4.Out",
          },
          "<");

      gsap.utils.toArray(textContainer.current.childNodes).reverse().forEach((word, i) => {
        reveal.current.fromTo(word, 
          {
            yPercent: -30 * (i + 2),
          },
          {
            yPercent:0,
            duration: 0.65,
          }, `<=${i * 0.05}`);
      })
    }
  }, []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: container.current,
      start: "top center",
      onEnter: () => {
        if (introPlayed) reveal.current.play();
      }
    });
  }, [introPlayed]);

  useEffect(() => {
    if (first) {
      dispatch({
        type: "setPartHeader",
        header: headingText,
      });
    }
    if (container.current) {
      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        end: "bottom top",
        onEnter: () => {
          dispatch({
            type: "hideProgress",
          });
          dispatch({
            type: "setPartHeader",
            header: headingText,
          });
          dispatch({
            type: "setColor",
            color: false,
          });
        },
        onEnterBack: () => {
          dispatch({
              type: "setColor",
              color: false,
            });
        },
        onLeave: () => {
          if (last) {
            dispatch({
              type: "showProgress",
            });
          }
        },
      });
    }
  }, []);

  return (
    <div className="relative w-full min-h-screen flex items-center overflow-hidden" ref={container}>
      <div className="absolute w-full h-full z-0 transform scale-105" ref={imageContainer}>
        <Image {...image} fill />
      </div>

      <div className="absolute w-full left-0 h-full z-0">
        <div className="transform w-full h-full" ref={leftMask}>
          <CornerMask />
        </div>
      </div>
      
      <div className="absolute w-full right-0 h-full z-0">
        <div className="transform w-full h-full" ref={rightMask}>
          <CornerMask flipX flipY bottom />
        </div>
      </div>
      

      <div 
        className="relative z-20 flex flex-col space-y-0 md:space-y-7 text-center w-max mx-auto"
        ref={textContainer}
      >
        <div className="mb-2 md:mt-0">
          <Knockout
            variant="label-lg"
            textColor="black"
            knockoutColor="bright-yellow"
          >
            {partText}
          </Knockout>
        </div>

        <div>
          <Knockout
            variant="h1"
            textColor="white"
            knockoutColor="black"
          >
            {heading}
          </Knockout>
        </div>

        <div>
          <Knockout
            variant="h1"
            textColor="black"
            knockoutColor="bright-yellow"
          >
            {keyword}
          </Knockout>
        </div>
      </div>

    </div>
  )
};

ManifestoHero.defaultProps = {};

ManifestoHero.propTypes = {};

export default ManifestoHero;
