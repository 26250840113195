import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import propTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Button, Icon, Text, Container } from "@atoms";
import { ProcessContent } from "@molecules";
import gsap from "gsap";

const ProcessContainer = () => {
  const [activeProcess, setActiveProcess] = useState(0);
  const [windowPosition, setWindowPosition] = useState(0);
  const [{ process: _p}, dispatch] = useAppState();
  const {
    content,
    process,
    location,
    containerColor,
    processArray: processes,
    stepNumberOnly,
  } = _p;

  const tl = useRef();
  const footer = useRef();
  const processRef = useRef();
  const closeButton = useRef();

  const currentProcess = content || 0;
  const next = currentProcess + 1;
  const previous = currentProcess - 1;

  const removeBreaks = num => {
    return processes[num].heading.replace(/[<]br[^>]*[>]/gi, ' ');
  };

  const handlePrevious = n => {
    if (n >= 0) {
      dispatch({
        type: "activeProcess",
        content: n,
        containerColor: processes[n].color,
      });
    }

    if (n === -1) {
      dispatch({
        type: "closeProcess",
      });
    }
  };

  const handleNext = n => {
    if (n < processes.length) {
      dispatch({
        type: "activeProcess",
        content: n,
        containerColor: processes[n].color,
      });
    }

    if (n === processes.length) {
      dispatch({
        type: "closeProcess",
      });
    }
  };

  useEffect(() => {
    setActiveProcess(currentProcess);
  }, [currentProcess]);

  useEffect(() => {
    if (processRef.current) {
      tl.current = gsap
        .timeline({ paused: true})
        .set(processRef.current,
          { 
            position: "fixed",
            display: "block", 
            pointerEvents: "auto",
            ...location,
          })
        .set(footer.current, { opacity: 0 })
        .set(closeButton.current, { opacity: 0 })
        .to(processRef.current,
          { 
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            duration: 0.35,
            ease: "power3.inOut",
            onReverseComplete: () => {
              processRef.current.scrollTo(0,0);
              dispatch({
                type: "clearProcessContent",
              });
            }
        })
        .to(footer.current, { opacity: 1, duration: 0.3 })
        .to(closeButton.current, { opacity: 1, duration: 0.3 }, "-=0.3");
    }
  }, [location]);

  useEffect(() => {
    if (process) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [process]);

  return (
    <div 
      ref={processRef}
      className={classNames("w-screen h-screen fixed hidden inset-0 z-50 overflow-y-scroll overscroll-contain flex",
        { "bg-blue" : containerColor === "blue" },
        { "bg-blue-bright" : containerColor === "bright-blue" },
        { "bg-bright-pink" : containerColor === "bright-pink" },
        { "bg-bright-yellow" : containerColor === "bright-yellow" },
        { "bg-bright-orange" : containerColor === "bright-orange" },
      )}
    >
      <div className="pt-24 pb-40">
        <Container variant="2xl">
          {processes && (
            <ProcessContent {...processes[activeProcess]} step={activeProcess + 1} stepNumberOnly={stepNumberOnly}/>
          )}
        </Container>
      </div>

      <div ref={footer} className="opacity-0 flex flex-row self-end justify-between bg-black fixed bottom-0 w-full p-4">
        <div>
          <button
            className="flex items-center space-x-2 p-1"
            aria-label="Go to the previous process" 
            onClick={() => {
              handlePrevious(previous);
            }}
          >
            <Icon name="inverseArrow" className="h-4 flip-x" fitHeight />
            <Text
              variant="label-sm"
              className="sr-only md:not-sr-only"
            >
              {previous >= 0 && (
                <span>Previous: {removeBreaks(previous)}</span>
              )}
              {previous === -1 && (
                <span>Back to Property Developer Services</span>
              )}
            </Text>
          </button>
        </div>

        <div className="self-end">
          <button
            type="button"
            className="flex items-center space-x-2 p-1"
            aria-label="Go to the next process" 
            onClick={() => {
              handleNext(next);
            }}
          >
            <Text
              variant="label-sm"
              className="sr-only md:not-sr-only"
            >
              {next < processes.length && (
                <span>Next: {removeBreaks(next)}</span>
              )}
              {next === processes.length && (
                <span>Back to Property Developer Services</span>
              )}
            </Text>
            <Icon name="inverseArrow" className="h-4" fitHeight />
          </button>
        </div>
      </div>

      <div className="fixed top-6 right-4 md:right-8" ref={closeButton}>
        <button
          type="button"
          aria-label="Close process" 
          onClick={() => { 
            dispatch({
              type: "closeProcess",
            });
          }}
          className="p-4"
        >
          <Icon name="close" className="text-black w-10 lg:w-14" />
        </button>
      </div>
    </div>
  );
};

ProcessContainer.defaultProps = {};

ProcessContainer.propTypes = {};

export default ProcessContainer;