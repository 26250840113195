import React, { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Knockout, Container, Image, Icon } from "@atoms";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StatsProject = ({ heading, subhead, list, stats }) => {
  const container = useRef();
  const statsContainer = useRef();

  useEffect(() => {
    if (statsContainer.current) {
      const tl = gsap.timeline({ paused: true })

      gsap.utils.toArray(statsContainer.current.children).forEach((stat, i) => {
        tl.fromTo(stat.querySelector(".back-cover"), { width: "100%" }, { width: 0, duration: 0.65 }, `<${i * 0.05}`)
        tl.fromTo(stat.querySelector(".stat-label"), { opacity: 0 }, { opacity: 1, duration: 0.25 }, `<0.1`)
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top center-=40px",
        once: true,
        onEnter: () => {
          tl.play();
        }
      });
    }
  }, []);

  return (
    <div className="pt-12 lg:pt-24 pb-20 lg:pt-16 lg:pb-28 overflow-hidden" ref={container}>
      <Container
        variant="xl"
        className="flex flex-col space-y-12"
      >
        <div className="flex flex-col lg:flex-row space-y-8 space-x-0 lg:space-x-8">
          <div className="w-full max-w-2xl">
            <Text
              variant="xl"
              className="mb-4 lg:mb-8"
            >
              {heading}
            </Text>

            <Text
              variant="body"
              className=""
            >
              {subhead}
            </Text>

            <ul className="mt-12 lg:mt-16">
              {list?.map((item, i) => {
                return (
                  <li className="flex mb-6">
                    <Icon
                      name="electric" 
                      className={classNames("h-5 lg:h-6 mt-0.5 lg:mt-2 mr-3",
                        { "text-blue": item.color === "blue" },
                        { "text-blue-bright": item.color === "bright-blue" },
                        { "text-bright-orange": item.color === "bright-orange" },
                        { "text-bright-yellow": item.color === "bright-yellow" },
                        { "text-bright-pink": item.color === "bright-pink" },
                      )}
                      fitHeight
                    />
                    <div>
                      <Text
                        variant="body"
                        className="mb-4"
                      >
                        {item.label}
                      </Text>
                      <Text
                        variant="sm"
                        className="max-w-md w-full"
                      >
                        {item.content}
                      </Text>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>

          <div
            className="max-w-unset lg:max-w-sm w-full mt-10 md:grid lg:block gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-1 grid-flow-row"
            ref={statsContainer}
          >
            {stats?.map((stat, i) => {
              return (
                <div className="mb-8 w-max">
                  <div className="mb-2 stat-container relative w-full">
                    <Knockout
                      variant="h2"
                      textColor="black"
                      knockoutColor={stat.color}
                    >
                      {stat.statistic}
                    </Knockout>
                    <div className="back-cover h-full absolute top-0 right-0 bg-black w-full" />
                  </div>
                  <div>
                    <Text
                      variant="label-lg"
                      className={classNames("stat-label opacity-0",
                        { "text-blue": stat.color === "blue" },
                        { "text-blue-bright": stat.color === "bright-blue" },
                        { "text-bright-orange": stat.color === "bright-orange" },
                        { "text-bright-yellow": stat.color === "bright-yellow" },
                        { "text-bright-pink": stat.color === "bright-pink" },
                      )}
                    >
                      {stat.label}
                    </Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

StatsProject.defaultProps = {};

StatsProject.propTypes = {};

export default StatsProject;
