import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const StickyContent = ({ heading, copy, items }) => {
  return (
    <div className="space-y-8">
      <Text variant="h5">
        {heading}
      </Text>

      <Text variant="xs" className="max-w-2xl w-full">
        {copy}
      </Text>

      <div className="grid grid-cols-2 gap-8 max-w-2xl w-full">
        {items?.map((item,i) => {
          return (
            <div className={classNames(
              "",
              { "col-span-1" : !item.full },
              { "col-span-full col-start-1" : item.full },
            )}>
              <Text variant="label-lg" className="text-bright-pink mb-8">{item.label}</Text>
              <Text variant="xs" className="mt-4">{item.content}</Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

StickyContent.defaultProps = {};

StickyContent.propTypes = {};

export default StickyContent;
