import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query {
    craft {
      globalSet(handle: "error") {
        ... on Craft_error_GlobalSet {
          heading0
          descriptor0
          errorCards {
            ... on Craft_errorCards_card_BlockType {
              cardTitle
              description
              cardLink {
                text
                url
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const previewQuery = `
  query {
    craft {
      globalSet(handle: "error") {
        ... on Craft_error_GlobalSet {
          heading0
          descriptor0
          errorCards {
            ... on Craft_errorCards_card_BlockType {
              cardTitle
              description
              cardLink {
                text
                url
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { heading0, descriptor0, copy0, errorCards, link0 } = craft.globalSet;
  return {
    heading: heading0,
    subhead: descriptor0,
    cards: errorCards?.map(card => {
      return {
        title: card.cardTitle,
        description: card.description,
        link: card.cardLink,
        image: resolveImage(card.image),
      }
    }),
    link: link0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
