import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const Event = ({ date, heading, description, link }) => {
  return (
    <div className="space-y-4">
      <Knockout variant="label" knockoutColor="bright-pink" textColor="black">
        {date}
      </Knockout>

      <Text variant="lg">
        {heading}
      </Text>

      <Text variant="sm" className="max-w-2xl w-full">
        {description}
      </Text>

      <Button to={link} border={false} arrow={false} underlineColor="bright-pink">
        <Text variant="label">
          Get the details
        </Text>
      </Button>
    </div>
  );
};

Event.defaultProps = {};

Event.propTypes = {};

export default Event;
