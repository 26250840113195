import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import {
  Container,
} from "@atoms";
import {
  CbCopy,
  CbImage,
  PbQuote,
  CbVideo,
} from "@molecules";
import {
  TextWithImage,
  ProcessServices,
  FullWidth,
  HeaderWithProjects,
  HeroContact,
  MediaEvents,
  CrewAbout,
} from "@organisms";
import { useAppState } from "@state/state";

const PageBuilder = ({ blocks, slug }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb?.map((block, i) => {
      const { type } = block || false;
      switch (type) {
        case "copy":
          return (
            <Container variant="xl" className="my-6">
              <CbCopy {...block} />
            </Container>
          );
        case "image":
          return (
            <Container variant="xl" className="my-6">
              <CbImage {...block} fluid />
            </Container>
          );
        case "quote":
          return (
            <div className="mb-6 quote-goes-here">
              <PbQuote {...block} />
            </div>
          );
        case "video":
          return (
            <Container variant="xl" className="my-6">
              <CbVideo {...block} fluid />
            </Container>
          );
        case "scrollingContent":
          return <ProcessServices {...block} stepNumberOnly />;
        case "imageWithCopy":
          return <TextWithImage {...block} />
        case "fullWidth":
          return <FullWidth {...block} />;
        case "selectProjects":
          return <HeaderWithProjects {...block} />;
        case "sideHeader":
          return <HeroContact {...block} />;
        case "stickyContent":
          return <MediaEvents {...block} />;
        case "team":
          return <CrewAbout {...block} />;
        default:
          return null;
      }
    });
  }
  return null;
};

PageBuilder.defaultProps = {};

PageBuilder.propTypes = {};

export default PageBuilder;
