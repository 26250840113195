import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Text, Button, CornerMask, Knockout, Icon } from "@atoms";

const HeroServices = ({ heading, description, image, button, list, removePadding }) => {
  return (
    <div className={classNames("relative overflow-hidden",
      { "pb-20" : button || list || removePadding },
      { "pb-96" : !button && !list && !removePadding },
    )}>
      <div className="bg-black absolute w-full h-full z-0 inset-0 opacity-30">
        <Image
          {...image}
          params={{
            blend: "353535",
            "blend-mode": "multiply",
            "blend-alpha": button || list ? "65" : "5",
            sat: -100,
          }} 
          fill
        />
      </div>
      <Container
        variant="xl"
        className="flex w-full relative z-10"
      >
        <div className="self-center justify-self-end pt-44 px-4 lg:px-0">
          <div className="relative">
            <Knockout
              variant="h2"
              textColor={ button || list ? "black" : "blue" }
              knockoutColor={ button || list ? "bright-yellow" : "black" }
            >
              {heading}
            </Knockout>
          </div>

          <div className="relative">
            <div className="mt-8 space-y-8">
              <Text 
                variant="lg"
                className={classNames("w-full",
                  { "max-w-xl" : button || list },
                  { "max-w-5xl" : !button && !list },
                )}
              >
                {description}
              </Text>

              {list && (
                <ul className="relative md:flex pt-2 pb-10 space-x-0 md:space-x-8 space-y-8 md:space-y-0">
                  {list?.map((item, i) => {
                    return (
                      <li 
                        className="flex space-x-4 content-top max-w-xs"
                        key={`${item}-${i}`}
                      >
                        <Icon 
                          name="electric"
                          fitHeight
                          className={classNames(
                            "h-5",
                            { "text-blue-bright" : i === 0 },
                            { "text-bright-pink" : i === 1 },
                            { "text-bright-orange" : i === 2 }
                          )}
                        />
                        <div>
                          <Text
                            variant="label-lg"
                            className="mb-4 -mt-1"
                          >
                            {item?.heading}
                          </Text>
                          <Text
                            variant="xs"
                            className=""
                          >
                            {item?.description}
                          </Text>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              )}

              {button && (
                <Button to={button?.url}>
                  <Text variant="body">
                    {button?.text}
                  </Text>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

HeroServices.defaultProps = {};

HeroServices.propTypes = {};

export default HeroServices;
