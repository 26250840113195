import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { Knockout, Text } from "@atoms";
import { useAppState } from "@state/state";
import classNames from "classnames";
import gsap from "gsap";

const ProcessItem = ({ info, color, delay, index }) => {
  const [{ process: _p }, dispatch] = useAppState();
  const { process, content } = _p;

  const tl = useRef();
  const coloredSlide = useRef();
  const blackSlide = useRef();

  useEffect(() => {
    if (coloredSlide.current && blackSlide.current) {
      tl.current = gsap
        .timeline({ paused: true })
        .set(coloredSlide.current, { height: "100%" })
        .set(blackSlide.current, { height: "100%" })
        .to(
          coloredSlide.current,
          { 
            height: "0%",
            duration: 0.35,
            ease: "power3.in",
            delay,
          },
          "+=0.2")
        .to(
          blackSlide.current,
          { 
            height: "0%",
            duration: 0.35,
            ease: "power3.in",
            delay,
          },
          "-=0.3");
    }
  }, []);

  useEffect(() => {
    if (process) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [process]);

  useEffect(() => {
    tl.current.restart();
  }, [content]);

  return (
    <div className="mb-6 md:mb-8 relative overflow-hidden">
      <div className="mb-2">
        <Knockout
          variant="label"
          textColor={color}
          knockoutColor="black"
          forceWrap
          className="leading-loose"
        >
          {info.label}
        </Knockout>
      </div>
      <Text
        variant="xs"
        className="text-black leading-relaxed block"
      >
        {info.content}
      </Text>
      <div className="h-full w-full absolute inset-x-0 bottom-0 bg-black" ref={blackSlide} />
      <div className={
        classNames("h-full w-full absolute inset-x-0 bottom-0",
          { "bg-blue" : color === "blue" },
          { "bg-blue-bright" : color === "bright-blue" },
          { "bg-bright-pink" : color === "bright-pink" },
          { "bg-bright-yellow" : color === "bright-yellow" },
          { "bg-bright-orange" : color === "bright-orange" },
        )} 
        ref={coloredSlide} />
      
    </div>
  );
};

ProcessItem.defaultProps = {};

ProcessItem.propTypes = {};

export default ProcessItem;
