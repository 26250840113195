import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";
import { ScrollingContainer, ProjectCard } from "@molecules";

const ProjectsHome = ({ headline, projects, link }) => {
  return (
    <div className="w-full py-8 md:py-24 lg:py-28 xl:py-32 relative">
      <div className="w-full relative">
        <div className="relaitve lg:absolute mb-4 top-6 left-12 lg:left-16 z-20 ">
          <Knockout
            variant="h2"
            textColor="blue"
            knockoutColor="black"
          >
            {headline}
          </Knockout>
        </div>

        <div className="pl-6">
          <ScrollingContainer className="relative pl-4 pr-4 md:pr-0 md:pl-24 xl:pl-32">
            {projects.map( (project, i) => {
              return (
                <ProjectCard {...project} key={i} i={i} />
              )
            })}
          </ScrollingContainer>  
        </div>
      </div>

      <div className="w-full relative px-4 sm:px-8 md:px-10 lg:px-12 xl:px-32 mt-8 relative flex flex-col">
        <div className="flex justify-end">
          <Button to={link?.url}>
            <Text variant="body">
              {link?.text}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

ProjectsHome.defaultProps = {};

ProjectsHome.propTypes = {};

export default ProjectsHome;
