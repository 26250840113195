import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const HeadingWithContent = ({ heading, subhead, description }) => {
  return (
    <Container
      variant="xl"
      className="relative z-10"
    >
      <div className="relative z-10 py-8 md:py-24 lg:py-28 xl:py-32 mx-auto">
        <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-8 md:space-x-24">
          <div className="flex-shrink-0 mb-4">
            <Knockout
              variant="h3"
              textColor="black"
              knockoutColor="bright-pink"
            >
              {heading}
            </Knockout>
          </div>
          
          <div className="w-full">
            <Text
              variant="lg"
              className="mb-4"
            >
              {subhead}
            </Text>
            <Text
              variant="sm"
            >
              {description}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  );
};

HeadingWithContent.defaultProps = {};

HeadingWithContent.propTypes = {};

export default HeadingWithContent;
