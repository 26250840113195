import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";
import process from "./reducers/process";
import form from "./reducers/form";
import manifesto from "./reducers/manifesto";

const reducer = {
  layout: layout.reducer,
  process: process.reducer,
  form: form.reducer,
  manifesto: manifesto.reducer,
};

const initialState = {
  layout: layout.initialState,
  process: process.initialState,
  form: form.initialState,
  manifesto: manifesto.initialState,
};

export const {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
} = createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
