import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text, Button } from "@atoms";

const Cta = ({ heading, descriptor, button }) => {
  return (
    <div className="bg-black text-white text-center p-8 w-full">
      <form className="space-y-4">
        <div>
          <Knockout
            variant="h5"
            textColor="black"
            knockoutColor="blue"
          >
            {heading}
          </Knockout>
        </div>
        <div>
          <Text className="mb-6">{descriptor}</Text>
        </div>

        <div className="flex items-center justify-center">
          <Button
            to={button.url}
            border={false}
            underlineColor="transparent"
            arrowColor="blue"
            arrowSize="sm"
          >
            <Text 
              variant="sm"
              className="mr-2"
            >
            {button.text}
            </Text>
          </Button>
        </div>
      </form>
      
    </div>
  );
};

Cta.defaultProps = {};

Cta.propTypes = {};

export default Cta;
