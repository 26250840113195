import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";
import { MemberCard } from "@molecules";

const CrewAbout = ({ heading, description, team }) => {
  return (
    <div className="w-full py-8 md:py-24 lg:py-28 xl:py-32 relative">
      <div className="space-y-8 text-center mb-24">
        <Text
          variant="h2"
          className="text-blue-bright"
        >
          {heading}
        </Text>

        <Text
          variant="lg"
          className="text-center text-white max-w-5xl w-full mx-auto px-8"
        >
          {description}
        </Text>
      </div>

      <div className="w-full max-w-8xl mx-auto px-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 grid-rows-2 gap-6 md:gap-y-12 lg:gap-y-20">
        {team?.map((member, i) => {
          return (
            <MemberCard {...member} key={i} />
          )
        })}
      </div>
    </div>
  );
};

CrewAbout.defaultProps = {};

CrewAbout.propTypes = {};

export default CrewAbout;
