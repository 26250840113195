import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";

const Share = ({ url: _url, color, small, hideShare }) => {
  const url = encodeURIComponent(_url);
  return (
    <div className={classNames("flex items-center",
      { "space-x-6" : !small },
      { "space-x-3" : small },
      { "space-x-6" : hideShare },
    )}>
      {!hideShare && (
        <Text variant={!small ? "label-lg" : "label" } className="-mt-1">
          Share
        </Text>
      )}
      <AppLink
        to={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        className={classNames(
          "transform hover:scale-105 transtion duration-200",
          { [color]: true }
        )}
      >
        <Icon
          name="facebook"
          className={ !small ? "w-6 h-6" : "w-4 w-4" }
        />
      </AppLink>
      <AppLink
        to={`https://twitter.com/intent/tweet?url=${url}`}
        className={classNames("", { [color]: true })}
      >
        <Icon
          name="twitter"
          className={ !small ? "w-6 h-6" : "w-4 w-4" }
        />
      </AppLink>
      <AppLink
        to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        className={classNames(
          "transform hover:scale-105 transtion duration-200",
          { [color]: true }
        )}
      >
        <Icon
          name="linkedin"
          className={ !small ? "w-6 h-6" : "w-4 w-4" }
        />
      </AppLink>
    </div>
  );
};

Share.defaultProps = {
  color: "text-cyan",
  hideShare: false,
};

Share.propTypes = {};

export default Share;
