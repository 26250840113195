import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text, Button, Field } from "@atoms";
import axios from "axios";
import gsap from "gsap";

const Form = ({ heading, descriptor, button, sidebar }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const errorMessage = useRef();
  const successMessage = useRef();

  useEffect(() => {
    if (success) {
      gsap.to(successMessage.current, {
        opacity: 1,
        duration: 0.2,
        display: "flex",
        // easing: "back",
      });
    }
  }, [success]);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const submitForm = () => {
    if (formData && !success) {
      axios
        .post(`/.netlify/functions/mailchimp`, JSON.stringify(formData), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(res => {
          if (!res.data.error) {
            setSuccess(true);
            return;
          }
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        });
    } else {
      setError("Please fill out all required fields");
    }
  };
  return (
    <div className="bg-black text-white text-center p-8 w-full my-6 ">
      <div className="space-y-4">
        <div>
          <Knockout
            variant="h5"
            textColor="black"
            knockoutColor="blue"
          >
            {heading}
          </Knockout>
        </div>

        <div>
          <Text className="mb-2">{descriptor}</Text>
        </div>
      </div>

      <div
        ref={errorMessage}
        className="mb-2 h-0 overflow-hidden"
      >
        <div className="p-3">{error}</div>
      </div>

      <div
        ref={successMessage}
        className="mb-2 hidden justify-center"
      >
        <Text variant="sm" className="p-3 text-center text-white">You have successfully subscribed to our insights.</Text>
      </div>

      <form 
        className={classNames("my-4 transition-opacity",
          { "opacity-0 hidden": success },
        )}
        onSubmit={e => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="mb-4 space-y-4">
          <div>
            <Field
              label="Name"
              name="name"
              placeholder="Name..."
              required={true}
              type="text"
              hideLabel
              light
              onChange={e => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
          </div>
          <div>
            <Field
              label="Email"
              name="email"
              placeholder="Your email..."
              required={true}
              type="text"
              hideLabel
              light
              onChange={e => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Button
            border={false}
            underlineColor="transparent"
            arrowColor="blue"
            arrowSize="sm"
            type="submit"
          >
            <Text 
              variant="sm"
              className="mr-2"
            >
            {button}
            </Text>
          </Button>
        </div>
      </form>
    </div>
  );
};

Form.defaultProps = {};

Form.propTypes = {};

export default Form;
