const reducer = (state, action) => {
  switch (action.type) {
    case "startIntro":
      return {
        ...state,
        introPlayed: false,
      };
    case "completeIntro":
      return {
        ...state,
        introPlayed: true,
      };
    case "setColor":
      return {
        ...state,
        color: action.color,
      };
    case "setFooterColor":
      return {
        ...state,
        footerColor: action.footerColor,
      }
    case "showProgress":
      return {
        ...state,
        showProgress: true,
      };
    case "hideProgress":
      return {
        ...state,
        showProgress: false,
      };
    case "setPartHeader":
      return {
        ...state,
        header: action.header,
      }
    case "setPartFooter":
      return {
        ...state,
        footer: action.footer,
      }
    case "showHeader":
      return {
        ...state,
        showHeaderText: true,
      };
    case "hideHeader":
      return {
        ...state,
        showHeaderText: false,
      };
    default:
      return state;
  }
};

const initialState = {
  introPlayed: false,
  color: false,
  footerColor: false,
  showProgress: false,
  header: "",
  footer: "",
  showHeaderText: true,
};

const process = {
  reducer,
  initialState,
};

export default process;
