import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroContact, ContactForm, HeaderWithProjects } from "@organisms";
import { useAppState } from "@state/state";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import MapStyles from "./MapStyles"; 

const Maps = () => {
  const containerSize = {
    width: "100%",
    height: "18rem",
    position: "relative",
  };
  const location = {
    address: "111 Mission Street Santa Cruz, CA 95060 USA",
    lat: 36.976841300873524,
    lng: -122.02826241576251,
  };
  return (
    <Map
      google={window.google}
      zoom={15}
      initialCenter={location}
      containerStyle={containerSize}
      styles={MapStyles}
    >
      <Marker position={location} />
    </Map>
  );
};

Maps.defaultProps = {};

Maps.propTypes = {};

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
})(Maps);
