import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Icon, Text, Image } from "@atoms";

const Quote = ({ quote, author, image, small }) => {
  return (
    <div className={classNames(
      "flex flex-col md:flex-row items-center ",
      { "justify-center space-y-12 md:space-x-24" : !small },
      { "md:space-x-16" : small },
    )}>
      {image?.url && (
        <div className={classNames(
          "bg-blue-bright border-12 border-black relative flex-shrink-0",
          { "w-32 h-32 md:w-52 md:h-52 " : !small },
          { "w-32 h-32 " : small },
        )}>
          <Image {...image} params={{ fit: "max" }} fill />
        </div>
      )}

      <div className={classNames(
        "",
        { "space-y-8" : !small },
        { "space-y-6" : small },
      )}>
        <div>
          <Icon
            name="quote"
            className={classNames(
              "",
              { "w-16 sm:w-20 md:w-24 lg:w-28 text-black" : !small },
              { "w-12 sm:w-16 lg:w-20 text-blue" : small },
            )}
          />
        </div>

        <div>
          <span className="text-neue-h4 lg:text-neue-h3 leading-normal font-sans text-black">
            {quote}
          </span>
        </div>

        <div>
          <span
            className={classNames(
              "uppercase block font-antarctican text-sm md:text-antarctican-h2 lg:text-antarctican-h1 font-bold leading-relaxed uppercase tracking-0.5",
              { "text-blue" : small },
              { "text-black" : !small },
            )}
          >
            {author}
          </span>
        </div>
      </div>
    </div>
  );
};

Quote.defaultProps = {};

Quote.propTypes = {};

export default Quote;