import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Knockout, Button } from "@atoms";
import { ProjectsGrid } from "@organisms";

const HeaderWithProjects = ({ heading, subhead, projects, cards, link }) => {
  return (
    <div className="py-32">
      <div className="text-center space-y-4 mb-8">
        <Knockout
          variant="h2"
          knockoutColor="bright-yellow"
          textColor="black"
        >
          {heading}
        </Knockout>
        <Text
          variant="lg"
          className="max-w-2xl w-full mx-auto"
        >
          {subhead}
        </Text>
      </div>
      
      {projects && (
        <ProjectsGrid projects={projects} horizontal header={false} />
      )}

      {cards && (
        <ProjectsGrid cards={cards} horizontal header={false} />
      )}

      <div className="text-center">
        <Button to={link?.url || "projects"}>
          <Text>
            {link?.text || "All the Projects"}
          </Text>
        </Button>
      </div>
    </div>
  );
};

HeaderWithProjects.defaultProps = {};

HeaderWithProjects.propTypes = {};

export default HeaderWithProjects;