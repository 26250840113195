import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Knockout, Button, Field } from "@atoms";
// import { Maps as Map } from "@molecules";
import { map } from "@images";
import { useAppState } from "@state/state";
import axios from "axios";
import gsap from "gsap";

const ContactForm = ({ info, mail }) => {
  const form =  useRef();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const [newsletter, setNewsletter] = useState(false)
  const errorMessage = useRef();
  const successMessage = useRef();

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const handleSubmit = e => {
    e.preventDefault();
    if (formData && !success) {
      const send = encode({
        "form-name": e.target.getAttribute("name"),
        ...formData,
      });
      axios
        .post(`/`, send, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          }
        })
        .then(() => {
          if (newsletter) {
            axios.post(`/.netlify/functions/mailchimp`, JSON.stringify(formData),
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            })
          }
        })
        .then(() => navigate(form.current.getAttribute("action")))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      setError("Please fill out all required fields");
    }
  };
  return (
    <Container
      variant="xl"
      className="relative z-10"
    >
      <div className="relative z-10 mx-auto pb-16">
        <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-8 md:space-x-20 space-y-4 sm:space-y-0 justify-start">
          <div className="w-full max-w-sm space-y-6">
            <Text variant="label" className="text-bright-pink">Contact Info</Text>
            <Text variant="xs">{info}</Text>

            <Text variant="label" className="text-bright-pink">Mailing Address</Text>
            <Text variant="xs">{mail}</Text>

            <div className="w-full h-72 relative overflow-hidden">
              <img src={map} className="absolute w-full -top-1/4"/>
            </div>
          </div>
          
          <div className="max-w-xl w-full">
            <div
              ref={errorMessage}
              className="mb-2 h-0 overflow-hidden"
            >
              <div className="p-3">{error}</div>
            </div>

            <form
              className="space-y-8"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              name="Contact Form"
              onSubmit={handleSubmit}
              action="/thank-you"
              ref={form}
            >
              <div className="flex space-x-8 w-full">
                <Field
                  label="First name"
                  name="firstName"
                  required={true}
                  type="text"
                  onChange={e => {
                    setFormData({ ...formData, firstName: e.target.value });
                  }}
                />
                <Field
                  label="Last name"
                  name="lastName"
                  required={true}
                  type="text"
                  onChange={e => {
                    setFormData({ ...formData, lastName: e.target.value });
                  }}
                />
              </div>
              <div>
                <Field
                  label="Email"
                  name="email"
                  required={true}
                  type="text"
                  onChange={e => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
              </div>
              <div>
                <Field
                  label="Phone number"
                  name="phoneNumber"
                  required={true}
                  type="text"
                  onChange={e => {
                    setFormData({ ...formData, phoneNumber: e.target.value });
                  }}
                />
              </div>
              <div>
                <Field
                  label="Message"
                  name="message"
                  required={false}
                  textarea
                  placeholder="Drop a message here..."
                  onChange={e => {
                    setFormData({ ...formData, message: e.target.value });
                  }}
                />
              </div>
              <div>
                <Field
                  label="Get our newsletter"
                  name="newsletter"
                  required={false}
                  type="checkbox"
                  onChange={e => {
                    setNewsletter(!newsletter);
                  }}
                />
              </div>
              <Button type="submit">
                <Text>Submit</Text>
              </Button>
              <input type="hidden" name="form-name" value="Contact Form" />
              <label className="sr-only">
                Don’t fill this out if you're human: 
                <input type="hidden" name="bot-field" />
              </label>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

ContactForm.defaultProps = {};

ContactForm.propTypes = {};

export default ContactForm;
