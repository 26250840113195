import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import {
  CbCopy,
  CbImage,
  CbQuote,
  CbCta,
  CbForm,
  CbVideo,
} from "@molecules";
import { useAppState } from "@state/state";

const ContentBuilder = ({ blocks, slug }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb.map((block, i) => {
      const { type } = block;
      switch (type) {
        case "copy":
          return (
            <div className="mb-6">
              <CbCopy {...block} />
            </div>
          );
        case "image":
          return (
            <div className="mb-6">
              <CbImage {...block} />
            </div>
          );
        case "quote":
          return (
            <div className="mb-6">
              <CbQuote {...block} />
            </div>
          );
        case "cta":
          return <CbCta {...block} />;
        case "form":
          return <CbForm {...block} />;
        case "video":
          return <CbVideo {...block} />;
        default:
          return null;
      }
    });
  }
  return null;
};

ContentBuilder.defaultProps = {};

ContentBuilder.propTypes = {};

export default ContentBuilder;
