import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state/state";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  border,
  arrow,
  arrowColor,
  arrowSize,
  underlineColor,
  inverse,
  borderColor,
  className: _className,
}) => {
  const [{ ec }, dispatch] = useAppState();

  let classes = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 group leading-tighter`;

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (border) {
    if (borderColor === "bright-blue") {
      classes += " border-3 border-blue-bright";
    } else {
      classes += " border-3 border-bright-yellow";
    }

    // set size
    switch (size) {
      case "fluid":
        classes += " p-2 text-neue-h4";
        break;
      case "md":
        classes += " px-4 py-3 text-neue-h5";
        break;
      case "sm":
        classes += " py-3 px-6 text-neue-h6";
        break;
      default:
        classes += " py-2.5 px-3.5 text-neue-h4";
        break;
    }
  }

  if (!border) {
    classes += " underline-1 p-0 px-0 sm:px-0 pb-2"
  }

  if (to && to.length > 1) {
    return (
      <LinkObj 
        to={to} 
        title={title} 
        className={classNames(
          `group ${classes} ${_className}`,
          { "underline-1-blue" : underlineColor === "blue" },
          { "underline-1-blue-bright" : underlineColor === "bright-blue" },
          { "underline-1-bright-pink" : underlineColor === "bright-pink" },
          { "underline-1-bright-yellow" : underlineColor === "bright-yellow" },
          { "underline-1-bright-orange" : underlineColor === "bright-orange" },
          { "underline-1-transparent" : underlineColor === "transparent" },
        )}
        onClick={onClick}
      >
        {border && borderColor !== "bright-blue" && (
          <div className="transition-all duration-300 absolute z-0 bg-bright-yellow inset-0 h-full w-0 group-hover:w-full p-0 m-0" />
        )}
        {border && borderColor === "bright-blue" && (
          <div className="transition-all duration-300 absolute z-0 bg-blue-bright inset-0 h-full w-0 group-hover:w-full p-0 m-0" />
        )}
        <span className={classNames(
          "transition-all duration-300 delay-150 flex relative z-10 text-center",
          { "group-hover:text-black mr-3.5" : border },
          )}
        >
          {children}
        </span>
        {arrow && (
          <Icon 
            name={!inverse ? "arrow" : "inverseArrow" }
            className={classNames(
              "transition-all duration-300 delay-150 relative z-10",
              { "text-bright-yellow group-hover:text-black" : !arrowColor },
              { "text-blue-bright group-hover:text-black" : borderColor === "bright-blue" && arrowColor === "bright-blue" },
              { "text-blue hover:text-blue" : arrowColor === "blue" },
              { "text-blue-bright hover:text-blue-bright" : arrowColor === "bright-blue" },
              { "text-bright-pink hover:text-bright-pink" : arrowColor === "bright-pink" },
              { "text-bright-yellow hover:text-bright-yellow" : arrowColor === "bright-yellow" },
              { "text-bright-orange hover:text-bright-orange" : arrowColor === "bright-orange" },
              { "w-5 md:w-6 lg:w-9" : arrowSize === "md"},
              { "w-4 md:w-5 lg:w-6" : arrowSize === "sm" },
              { "transform group-hover:translate-x-1" : arrow && !border },
            )}
          />
        )}
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(
          `group ${classes} ${_className}`,
          { "underline-1-blue" : underlineColor === "blue" },
          { "underline-1-blue-bright" : underlineColor === "bright-blue" },
          { "underline-1-bright-pink" : underlineColor === "bright-pink" },
          { "underline-1-bright-yellow" : underlineColor === "bright-yellow" },
          { "underline-1-bright-orange" : underlineColor === "bright-orange" },
          { "underline-1-transparent" : underlineColor === "transparent" },
        )}
      onClick={onClick}
    >
      {border && borderColor !== "bright-blue" && (
        <div className="transition-all duration-300 absolute z-0 bg-bright-yellow inset-0 h-full w-0 group-hover:w-full p-0 m-0" />
      )}
      {border && borderColor === "bright-blue" && (
        <div className="transition-all duration-300 absolute z-0 bg-blue-bright inset-0 h-full w-0 group-hover:w-full p-0 m-0" />
      )}
      <span className={classNames(
        "transition-all duration-300 delay-150 flex relative z-10 text-center",
        { "group-hover:text-black mr-3.5" : border },
        )}
      >
        {children}
      </span>
      {arrow && (
        <Icon 
          name={!inverse ? "arrow" : "inverseArrow" }
          className={classNames(
            "transition-all duration-300 delay-150 relative z-10",
            { "text-bright-yellow group-hover:text-black" : !arrowColor },
            { "text-blue-bright group-hover:text-black" : borderColor === "bright-blue" && arrowColor === "bright-blue" },
            { "text-blue hover:text-blue" : arrowColor === "blue" },
            { "text-blue-bright hover:text-blue-bright" : arrowColor === "bright-blue" },
            { "text-bright-pink hover:text-bright-pink" : arrowColor === "bright-pink" },
            { "text-bright-yellow hover:text-bright-yellow" : arrowColor === "bright-yellow" },
            { "text-bright-orange hover:text-bright-orange" : arrowColor === "bright-orange" },
            { "w-5 md:w-6 lg:w-9" : arrowSize === "md"},
            { "w-4 md:w-5 lg:w-6" : arrowSize === "sm" },
            { "transform group-hover:translate-x-1" : arrow && !border },
          )}
        />
      )}
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
  border: true,
  arrow: true,
  arrowSize: "md",
  inverse: false,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  border: propTypes.bool,
  arrow: propTypes.bool,
  inverse: propTypes.bool,
};

export default Button;
