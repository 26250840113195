import React from "react";
import propTypes from "prop-types";
import { Text, Container, Knockout, Button } from "@atoms";

const ManifestoCtaHome = ({ heading, subhead, description, button }) => {
  return (
    <div className="py-8">
      <Container
        variant="lg"
        className="my-24"
      >
        <div className="w-full">
          <div className="flex flex-col lg:flex-row space-y-8 md:space-y-0 justify-between">
            <div className="max-w-md w-full">
              <Text variant="h2" className="max-w-sm w-full mb-8 pr-4 text-blue-bright">
                {heading}
              </Text>

              <Text variant="body" className="max-w-xs w-full mb-8">
                {subhead}
              </Text>
            </div>

            <div className="max-w-unset lg:max-w-xl w-full">
              <div className="w-full columns-1 md:columns-2 gap-10 pb-20">
                <Text variant="sm" className="h-full pb-0 lg:pb-20">{description}</Text>
              </div>

              <div className="text-right">
                <Button
                  to={button?.url}
                  borderColor="bright-blue"
                  arrowColor="bright-blue"
                >
                  <Text variant="body">{button?.text}</Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

ManifestoCtaHome.defaultProps = {};
ManifestoCtaHome.propTypes = {};

export default ManifestoCtaHome;
