import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const FullWidth = ({ heading, subhead, link, image }) => {
  return (
    <div className="pt-12 pb-20 lg:pt-16 lg:pb-28 relative">
      <div className="bg-black absolute w-full h-full z-0 inset-0 opacity-30">
        <Image 
          {...image} 
          params={{ 
            blend: "555",
            "blend-mode": "multiply",
            "blend-alpha": "65",
            sat: -100,
          }} 
          fill
        />
      </div>

      <Container
        variant="xl"
        className="text-center relative z-10"
      >
        <div className="space-y-8">
          <Text
            variant="h4"
            className="text-white "
          >
            {heading}
          </Text>

          <Text
            variant="body"
            className="text-center text-white max-w-2xl w-full mx-auto"
          >
            {subhead}
          </Text>

          <Button
            to={link?.url}
          >
            {link?.text}
          </Button>
        </div>
      </Container>
    </div>
  );
};

FullWidth.defaultProps = {};

FullWidth.propTypes = {};

export default FullWidth;
