import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Quote, Container, Image } from "@atoms";

const PbQuote = props => {
  return (
    <div className="bg-bright-yellow pt-12 pb-20 lg:pt-16 lg:pb-28 overflow-hidden">
      <Container
        variant="xl"
        className="flex flex-col space-y-12"
      >
        <div>
          <Quote {...props} />
        </div>
      </Container>
    </div>
  );
};

PbQuote.defaultProps = {};

PbQuote.propTypes = {};

export default PbQuote;
