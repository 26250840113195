import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@atoms";

const Knockout = ({ children, variant, textColor, knockoutColor, forceWrap, className: _className, noPadding }) => {
  return (
    <Text
      variant={variant}
      className={classNames(
        `relative leading-tight inline box-decoration-clone ${_className}`,
        { "text-black": textColor === "black" },
        { "text-blue": textColor === "blue" },
        { "text-blue-bright": textColor === "bright-blue" },
        { "text-bright-orange": textColor === "bright-orange" },
        { "text-bright-yellow": textColor === "bright-yellow" },
        { "text-bright-pink": textColor === "bright-pink" },
        { "bg-black": knockoutColor === "black" },
        { "bg-blue": knockoutColor === "blue" },
        { "bg-blue-bright": knockoutColor === "bright-blue" },
        { "bg-bright-yellow": knockoutColor === "bright-yellow" },
        { "bg-bright-orange": knockoutColor === "bright-orange" },
        { "bg-bright-pink": knockoutColor === "bright-pink" },
        { "sm:whitespace-nowrap": !forceWrap },
        { "px-2 md:px-4": 
          (variant === "h1" ||
          variant === "h2" ||
          variant === "manifesto-h1" ||
          variant === "manifesto-h2" ||
          variant === "h3" ||
          variant === "h4" ||
          variant === "h5") && !noPadding
        },
        { "p-0": variant === "manifesto-h2" && noPadding },
        { "px-2 pb-1": variant === "label-lg" || variant === "label" || variant === "label-sm"},
      )}
    >
      {children}
    </Text>
  );
};

Knockout.defaultProps = {};

Knockout.propTypes = {};

export default Knockout;
