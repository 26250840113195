import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Knockout, Button } from "@atoms";
import { PostCard } from "@molecules";

const PostsHome = ({ heading, subhead, link, posts }) => {
  return (
    <Container 
      variant="xl"
      className="overflow-hidden"
    >
      <div className="py-8 md:py-24 lg:py-28 xl:py-32">
        <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-8 md:space-x-24">
          <div className="flex-shrink-0">
            <Knockout
              variant="h3"
              textColor="black"
              knockoutColor="blue"  
            >
              {heading}
            </Knockout>
          </div>
          
          <div className="mt-6 sm:mt-0 pr-16 lg:pr-24">
            <Text
              variant="lg"
            >
              {subhead}
            </Text>
          </div>
        </div>

        <div className="mt-16 sm:mt-24 flex flex-col md:flex-row items-center md:items-start md:justify-between space-x-0 md:space-x-8">
          {posts.map((post, i) => {
            return (
              <PostCard {...post} key={`post-${i}`} />
            )
          })}
        </div>

        <div className="w-full relative mt-8 relative flex justify-end">
          <Button to={link?.url}>
            <Text variant="body">
              {link?.text}
            </Text>
          </Button>
        </div>
      </div>
    </Container>
  );
};

PostsHome.defaultProps = {};

PostsHome.propTypes = {};

export default PostsHome;
