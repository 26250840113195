import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Knockout, Container, Button, Icon } from "@atoms";
import { ManifestoContact, ManifestoNewsletter, ManifestoShare } from "@molecules";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ManifestoFooter = ({ data }) => {
  const container = useRef();
  const [{ manifesto }, dispatch] = useAppState();
  const { footerColor } = manifesto;

  useEffect(() => {
    if (container.current) {
      ScrollTrigger.create({
        trigger: container.current,
        start: "top top+=20px",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "hideProgress",
          });
          dispatch({
            type: "hideHeader",
          });
        },
        onLeaveBack: () => {
          dispatch({
            type: "showProgress",
          });
          dispatch({
            type: "showHeader",
          });
        },
      });
    }
  }, []);

  return (
    <div ref={container}>
      <Container
        variant="2xl"
        className="min-h-screen flex items-center"
      >
        <div className="w-full justify-between py-8">
          <div className="flex items-baseline justify-center space-x-6 mb-16">
            <Text
              variant="manifesto-h2"
              className="text-white inline-block"
            >
              <span>
                Let's Build the future 
                <Knockout
                  knockoutColor="blue"
                  textColor="black"
                  className="inline-block ml-3 md:ml-6 px-2 md:px-4"
                >
                  Together
                </Knockout>
              </span>
            </Text>
            
          </div>

          <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-center space-y-8 lg:space-y-0 lg:space-x-8">
            {data?.map((block, i, array) => {
              const { type } = block;
              switch (type) {
                case "contactUs":
                  return <ManifestoContact {...block} />;
                case "newsletter":
                  return <ManifestoNewsletter {...block} />;
                case "share":
                  return <ManifestoShare {...block} />;
                default:
                  return null;
              }
            })}
          </div>

          <div className="max-w-md w-full mx-auto text-center mt-20 pb-10">
            <Icon name="logo" className="h-11 text-blue" fitHeight />
            <Text variant="label-sm" className="mt-2">Be Electric</Text>
          </div>
        </div>
      </Container>
    </div>
  );
};

ManifestoFooter.defaultProps = {};

ManifestoFooter.propTypes = {};

export default ManifestoFooter;