import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Text, Button, CornerMask, Knockout } from "@atoms";

const Hero = ({ heading, description, image, button, categories }) => {
  return (
    <div className="flex w-full relative h-auto lg:h-hero">
    
      <div className="w-full lg:w-1/2 h-96 lg:h-full bg-blue-bright absolute z-0">
        <Image {...image} params={{ fit: "max" }} fill />
      </div>

      <CornerMask />

      <div className="w-full pt-80 lg:pt-0 lg:w-1/2 lg:absolute lg:right-0 z-20 self-center justify-self-end pt-24 px-4 lg:px-0">
        <div className="relative lg:-left-64 ">
          <Knockout
            variant="h1"
            textColor="black"
            knockoutColor="bright-yellow"
          >
            {heading}
          </Knockout>
        </div>

        <div className="relative lg:pl-9">
          <div className="max-w-md w-full mt-8 space-y-8">
            <Text variant="lg">
              {description}
            </Text>

            {button && (
              <Button to={button?.url}>
                <Text variant="body">
                  {button?.text}
                </Text>
              </Button>
            )}

            {categories && (<ul className="mt-4">
              {categories?.map((category, i) => {
                return (
                  <li className="inline-block mr-4 mb-2">
                    <Text
                      variant="label"
                      className="text-blue"
                    >
                      <span>
                        {category.title}
                        {
                          i !== (categories.length - 1) ? 
                          "," : 
                          "" 
                        }
                      </span>
                    </Text>
                  </li>
                  )
              })}
            </ul>)}
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.defaultProps = {};

Hero.propTypes = {};

export default Hero;
