import resolveImage from "./resolveImage";

const resolveSidebar = _sidebar => {
  if (_sidebar && _sidebar.length) {
    const sidebar = [..._sidebar];
    return sidebar.map(block => {
      switch (block.type) {
        case "relatedArticles":
          return {
            type: block.type,
            relatedArticles: block.relatedArticle.map(post => {
              return {
                title: post.title,
                description: post.descriptor0,
                category: post.categories?.[0].slug,
                image: resolveImage(post.heroImage),
                author: post.authors[0].title,
                date: post.postDate,
                link: post.url,
              };
            }),
          };
        case "relatedCaseStudies":
          return {
            type: block.type,
            relatedCaseStudies: block.relatedCaseStudy.map(post => {
              return {
                title: post.title,
                category: post.projectCategories?.[0]?.slug?.replace("-", " "),
                image: resolveImage(post.heroBackgroundImage),
                link: post.url,
                hideReadOn: true,
              };
            }),
          };
        case "cta":
          return {
            type: block.type,
            heading: block.heading,
            descriptor: block.descriptor,
            button: block.button,
          };
        case "form":
          return {
            type: block.type,
            heading: block.heading,
            descriptor: block.descriptor,
            button: block.buttonText,
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolveSidebar;
