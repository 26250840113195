import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";

const HeroPost = ({ heading, description, label, image, byline }) => {
  return (
    <div className="mb-6">
      <div className="space-y-4">
        {image && (
          <div className="w-full h-96 mb-4">
            <Image {...image} fill />
          </div>
        )}

        <div>
          <Knockout
            variant="label"
            knockoutColor="blue"
            textColor="black"
          >
            {label}
          </Knockout>
        </div>

        <div>
          <Text
            variant="xl"
            className="text-black"
          >
            {heading}
          </Text>
        </div>

        <div>
          <Text
            variant="xs"
            className="text-grey"
          >
            <span>{byline}</span>
          </Text>
        </div>

        <div>
          <Text 
            variant="sm"
          >
            {description}
          </Text>
        </div>
        
      </div>
    </div>
  );
};

HeroPost.defaultProps = {};

HeroPost.propTypes = {};

export default HeroPost;