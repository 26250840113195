import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { AppLink } from "@base";
import { Text, Knockout, Button, Container } from "@atoms";

const JobsAbout = ({ heading, description, link, jobs }) => {
  return (
    <Container 
      variant="md"
      className="pb-20 flex flex-col md:flex-row place-content-between md:space-x-20"
    >
      <div className="space-y-6 mb-12">
        <Knockout
          variant="label"
          knockoutColor="blue"
          textColor="black"
        >
          Careers
        </Knockout>
        
        <Text variant="h4">{heading}</Text>
        <Text variant="sm" className="max-w-sm w-full">{description}</Text>

        <Button
          to={link?.url}
          border={false}
          arrow={false}
          underlineColor="blue"
        >
          <Text variant="label">
            {link?.text}
          </Text>
        </Button>
      </div>

      <div className="space-y-8">
        {jobs?.map((job, i) => {
          return (
            <div className="max-w-sm w-full" key={i}>
              <AppLink to={job.link}>
                <Text variant="label" className="text-blue mb-4">{job.title}</Text>
                <Text variant="xs">{job.description}</Text>
              </AppLink>
            </div>
          )
        })}
      </div>
    </Container>
  );
};

JobsAbout.defaultProps = {};

JobsAbout.propTypes = {};

export default JobsAbout;
