import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Knockout } from "@atoms";

const MemberCard = ({ name, title, url, headshot, large }) => {
  return (
    <div className={classNames(
      "group",
      { "col-span-3 lg:col-span-4 row-span-2" : large },
      { "col-span-3 row-span-2" : !large },
    )}>
      <div className="transition-all transform duration-200 relative mb-8 filter grayscale group-hover:filter-none">
        <Image
          {...headshot}
          params={{
            sat: -30,
          }}
        />
      </div>
      <Knockout
        variant={large ? "label" : "label-sm"}
        knockoutColor="bright-blue"
        textColor="black"
        forceWrap
        className="leading-loose"
      >
        {title}
      </Knockout>
      <Text variant={large ? "lg" : "body"} className="my-4">
        {name}
      </Text>
    </div>
  );
};

MemberCard.defaultProps = {};

MemberCard.propTypes = {};

export default MemberCard;
