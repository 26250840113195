import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text } from "@atoms";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ManifestoLanding = ({ color, heading, keyword, subhead, first, index }) => {
  const container = useRef();
  const headerColorCover = useRef();
  const headerBlackCover = useRef();
  const numberColorCover = useRef();
  const numberBlackCover = useRef();
  const messageColorCover = useRef();
  const messageBlackCover = useRef();

  const [{ manifesto }, dispatch] = useAppState();
  const { footerColor } = manifesto;
  const number = index < 10 ? `0${index + 1}` : index + 1;
  const leaveBackColor = {
    0: "bright-blue",
    1: "bright-pink",
    2: "bright-orange",
    3: "bright-yellow",
  };

  useEffect(() => {
    if (container.current) {
      const reveal = gsap.timeline({ paused: true })
      .set(headerColorCover.current, { height: "100%" })
      .set(headerBlackCover.current, { height: "100%" })
      .set(numberColorCover.current, { height: "100%" })
      .set(numberBlackCover.current, { height: "100%" })
      .set(messageColorCover.current, { height: "100%" })
      .set(messageBlackCover.current, { height: "100%" })
      .to(
        headerColorCover.current,
        { 
          height: "0%",
          duration: 0.45,
          ease: "power3.in",
        },
        "+=0.4")
      .to(
        headerBlackCover.current,
        { 
          height: "0%",
          duration: 0.35,
          ease: "power3.in",
        },
        "-=0.3")
      .to(
        numberColorCover.current,
        { 
          height: "0%",
          duration: 0.45,
          ease: "power3.in",
        },
        "<")
      .to(
        numberBlackCover.current,
        { 
          height: "0%",
          duration: 0.35,
          ease: "power3.in",
        },
        "-=0.3")
      .to(
        messageColorCover.current,
        { 
          height: "0%",
          duration: 0.45,
          ease: "power3.in",
        },
        "<")
      .to(
        messageBlackCover.current,
        { 
          height: "0%",
          duration: 0.35,
          ease: "power3.in",
        },
        "-=0.3");

      // The animation playing in this self contained ScrollTrigger
      // is due to the animation playing for all of them if set up as
      // a gsap.to(...)
      ScrollTrigger.create({
        trigger: container.current,
        start: "top center",
        onEnter: () => {
          reveal.play();
        }
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "setColor",
            color: "black",
          });
          dispatch({
            type: "setFooterColor",
            footerColor: "black",
          });
          dispatch({
            type: "setPartFooter",
            footer: keyword,
          });

          if (first) {
            dispatch({
              type: "showProgress",
            });
          }
        },
        onEnterBack: () => {
          dispatch({
            type: "setColor",
            color: "black",
          });
          dispatch({
            type: "setPartFooter",
            footer: keyword,
          });
        },
        onLeave: () => {
          dispatch({
            type: "setColor",
            color: color,
          });
          dispatch({
            type: "setFooterColor",
            footerColor: color,
          });
        },
        onLeaveBack: () => {
          if (first) {
            dispatch({
              type: "hideProgress",
            });
          } else {
            dispatch({
              type: "setColor",
              color: leaveBackColor[index - 1],
            });
          }
        },
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top bottom",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "setFooterColor",
            footerColor: "black",
          });
        },
        onLeaveBack: () => {
          dispatch({
            type: "setFooterColor",
            footerColor: leaveBackColor[index - 1],
          });
        },
      });
    }
  }, []);

  return (
    <div 
      className={classNames("relative w-full min-h-screen flex items-center",
        { "bg-blue": color === "blue" },
        { "bg-blue-bright": color === "bright-blue" },
        { "bg-bright-yellow": color === "bright-yellow" },
        { "bg-bright-orange": color === "bright-orange" },
        { "bg-bright-pink": color === "bright-pink" },
      )}
      ref={container}
    >
      <div className="w-max mx-auto px-6 flex flex-col ">
        <div className="mb-8 relative self-start">
          <Text variant="manifesto-h1" className="text-black">{heading}</Text>
          <Knockout variant="manifesto-h1" textColor={color} knockoutColor="black">{keyword}</Knockout>

          <div className="h-full w-full absolute inset-x-0 bottom-0 bg-black" ref={headerBlackCover} />
          <div className={
            classNames("h-full w-full absolute inset-x-0 bottom-0",
              { "bg-blue" : color === "blue" },
              { "bg-blue-bright" : color === "bright-blue" },
              { "bg-bright-pink" : color === "bright-pink" },
              { "bg-bright-yellow" : color === "bright-yellow" },
              { "bg-bright-orange" : color === "bright-orange" },
            )} 
            ref={headerColorCover} />
        </div>

        <div className="ml-0 md:ml-20 flex max-w-3xl w-full">
          <div className="relative">
          <Text variant="manifesto-h2" className="text-black mr-4 md:mr-8">
            {number}
          </Text>
          <div className="h-full w-full absolute inset-x-0 bottom-0 bg-black" ref={numberBlackCover} />
          <div className={
            classNames("h-full w-full absolute inset-x-0 bottom-0",
              { "bg-blue" : color === "blue" },
              { "bg-blue-bright" : color === "bright-blue" },
              { "bg-bright-pink" : color === "bright-pink" },
              { "bg-bright-yellow" : color === "bright-yellow" },
              { "bg-bright-orange" : color === "bright-orange" },
            )} 
            ref={numberColorCover} />
          </div>

          <div className="relative">
          <Text variant="lg" className="text-black">{subhead}</Text>
          <div className="h-full w-full absolute inset-x-0 bottom-0 bg-black" ref={messageBlackCover} />
          <div className={
            classNames("h-full w-full absolute inset-x-0 bottom-0",
              { "bg-blue" : color === "blue" },
              { "bg-blue-bright" : color === "bright-blue" },
              { "bg-bright-pink" : color === "bright-pink" },
              { "bg-bright-yellow" : color === "bright-yellow" },
              { "bg-bright-orange" : color === "bright-orange" },
            )} 
            ref={messageColorCover} />
          </div>
        </div>
      </div>
    </div>
  )
};

ManifestoLanding.defaultProps = {};

ManifestoLanding.propTypes = {};

export default ManifestoLanding;
