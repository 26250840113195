import resolveImage from "./resolveImage";

const resolveMeta = meta => {
  const { title, metaTitle, metaImage, metaDescription, slug, url } = meta;
  return {
    title: metaTitle || title,
    image: resolveImage(metaImage),
    description: metaDescription,
    appendTitle: !metaTitle?.length,
    slug,
    url,
  };
};

export default resolveMeta;
