import resolveImage from "./resolveImage";
import resolveBreaks from "./resolveBreaks";
import resolveMeta from "./resolveMeta";

const resolveManifesto = mb => {
  if (mb) {
    const {
      metaDescription,
      metaTitle,
      metaImage,
      title,
      uid,
      slug,
      url,
      manifestoFooter,
      manifestoSlide,
    } = {
      ...mb,
    };

    const slides = manifestoSlide.map((block, i, array) => {
      const { type, uid } = block;
      switch (type) {
        case "intro":
          return {
            type,
            uid,
            firstHeading: block.firstHeading || "The Future Is",
            secondHeading: block.secondHeading || "Seize Your",
            keywords: block.keywords,
            secondKeyword: block.secondSectionKeyword,
            subhead: block.subhead,
          };
        case "hero":
          return {
            type,
            uid,
            heading: block.heading || "The New Energy",
            keyword: block.keyword,
            image: resolveImage(block.image),
          };
        case "twoColumns":
          return {
            type,
            uid,
            leftColumn: block.leftColumn,
            rightColumn: block.rightColumn,
            keyword: block.keyword,
          };
        case "sectionLanding":
          return {
            type,
            uid,
            color: block.color,
            heading: block.heading || "The Future Is",
            keyword: block.keyword,
            subhead: block.subhead,
          };
        case "threeColumns":
          return {
            type,
            uid,
            heading: block.heading,
            subhead: block.subhead,
            copy: {
              leftColumn: block.copy[0].leftColumn,
              rightColumn: block.copy[0].rightColumn,
            },
            color: block.color,
            keyword: block.keyword,
            dontSetFooter: array[i + 1]?.type === "sectionLanding",
          };
        case "twoColumnsWithPullQuote":
          return {
            type,
            uid,
            heading: block.heading,
            subhead: block.subhead,
            pullQuote: block.pullQuote,
            copy: block.copy,
            color: block.color,
            keyword: block.keyword,
            dontSetFooter: array[i + 1]?.type === "sectionLanding",
          };
        case "largeText":
          return {
            type,
            uid,
            heading: resolveBreaks(block.heading),
            copy: block.copy,
            color: block.color,
            keyword: block.keyword,
          };
        default:
          return null;
      }
    });

    const footer = manifestoFooter.map(block => {
      const { type, uid } = block;
      switch (type) {
        case "contactUs":
          return {
            type,
            uid,
            heading: block.heading,
            subhead: block.subheading,
            contact: block.contactLink,
            work: block.workLink,
          };
        case "newsletter":
          return {
            type,
            uid,
            heading: block.heading,
            subhead: block.subhead,
            button: block.buttonText,
          };
        case "share":
          return {
            type,
            uid,
            heading: block.heading,
            subhead: block.subheading,
            button: block.aboutUsLink,
            url,
          };
        default:
          return null;
      }
    });

    return {
      url,
      uid,
      slug,
      title,
      meta: resolveMeta({
        title,
        metaTitle,
        metaImage,
        metaDescription,
        slug,
        url,
      }),
      manifesto: slides,
      footer,
    };
  }
  return null;
};

export default resolveManifesto;
