import resolveImage from "./resolveImage";

const resolveCB = cb => {
  if (cb && cb.length) {
    return cb.map(block => {
      const { type, uid } = block;
      switch (type) {
        case "copy":
          return { uid, type, copy: block.copy };
        case "image":
          return {
            uid,
            type,
            image: resolveImage(block.image),
            caption: block.caption,
          };
        case "quote":
          return {
            uid,
            type,
            quote: block.quote,
            author: block.quoteAuthor,
            image: resolveImage(block.quoteAuthorImage),
          };
        case "cta":
          return {
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            button: block.button,
          };
        case "form":
          return {
            uid,
            type,
            heading: block.heading,
            descriptor: block.descriptor,
            button: block.buttonText,
          };
        case "video":
          return {
            uid,
            type,
            videoUrl: block.videoUrl,
            caption: block.caption,
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolveCB;
