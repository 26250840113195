import React from "react";
import propTypes from "prop-types";

const Container = ({
  children,
  className: _className,
  variant,
  padding,
  interior,
}) => {
  let classes;
  const px = "px-4 sm:px-8 md:px-10 lg:px-12 xl:px-20";
  // if (flush) {
  //   margin = "-mx-3 sm:-mx-6 md:-mx-8 lg:-mx-12";
  // }
  switch (variant) {
    case "xxs":
      classes = "w-full max-w-xl xl:max-w-2xl mx-auto";
      break;
    case "xs":
      classes = "w-full max-w-3xl xl:max-w-4xl mx-auto";
      break;
    case "sm":
      classes = "w-full max-w-4xl xl:max-w-5xl mx-auto";
      break;
    case "xl":
      classes = "w-full max-w-5xl xl:max-w-6.5xl mx-auto";
      break;
    case "2xl":
      classes = "w-full max-w-7xl xl:max-w-8xl mx-auto";
      break;
    default:
      classes = "w-full max-w-5xl xl:max-w-6xl mx-auto";
      break;
  }
  if (padding && !interior) {
    return (
      <div
        className="px-0
       sm:px-4 lg:px-12 xl:px-8"
      >
        <div className={`w-full ${classes} ${_className} ${px}`}>
          {children}
        </div>
      </div>
    );
  }
  if (padding && interior) {
    return (
      <div className="-px-3 sm:px-0">
        <div className={`w-full ${classes} ${_className} ${px}`}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={`w-full ${classes} ${_className} ${px}`}>{children}</div>
  );
};

Container.defaultProps = {
  variant: "md",
  interior: false,
  padding: false,
  className: "",
};

Container.propTypes = {
  className: propTypes.string,
  variant: propTypes.string,
  padding: propTypes.bool,
  interior: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Container;
