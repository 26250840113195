import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";
import { ProjectGridCard, ErrorCard } from "@molecules";

const ProjectsGrid = ({ projects, cards, horizontal, header }) => {
  return (
    <div className="pt-12 pb-20 lg:pt-16 lg:pb-28 overflow-hidden">
      <Container
        variant="xl"
        className="grid grid-cols-6 gap-8 relative"
      >
        {header && (
          <div className="absolute top-10 left-0 z-20 ">
            <Knockout
              variant="h3"
              textColor="blue"
              knockoutColor="black"
            >
              <span>All The<br/>Projects</span>
            </Knockout>
          </div>
        )}
        {projects?.map(( project, i, array) => {
          return (
            <ProjectGridCard
              {...project}
              key={i} 
              size={i === 0 && !horizontal ? "lg" : "sm"}
              notFull={array.length < 3}
            />
          )
        })}
        {cards?.map(( card, i) => {
          return (
            <ErrorCard
              {...card}
              key={i} 
            />
          )
        })}
        {!horizontal && (
          <div className="block lg:hidden col-span-full">
            <Button>
              <Text>
                All the Projects
              </Text>
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

ProjectsGrid.defaultProps = {
  header: true,
};

ProjectsGrid.propTypes = {};

export default ProjectsGrid;
