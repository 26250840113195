import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";
import { gdpr } from "@utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "submitting":
      return {
        ...state,
        isSubmitting: true,
      };

    case "success":
      return {
        ...state,
        isSubmitting: false,
        success: true,
        error: false,
      };

    default:
      return state;
  }
};

const initialState = {
  error: false,
  success: false,
  isSubmitting: false,
};

export default {
  reducer,
  initialState,
};
