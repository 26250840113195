import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { 
  ManifestoIntro,
  ManifestoHero,
  ManifestoTwoColumns,
  ManifestoLanding,
  ManifestoThreeColumns,
  ManifestoLargeText,
  ManifestoFooter,
  ManifestoOverlay,
} from "@molecules";

const ManifestoContainer = ({ manifestoSlides, footerSlides }) => {

  const isLast = (type, i, block, array) => {
    const filteredArray = array.filter(b => b.type === type);
    const last = filteredArray[filteredArray.length - 1]
    return last.uid === block.uid;
  };
  const isFirst = (type, i, block, array) => {
    const filteredArray = array.filter(b => b.type === type);
    const first = filteredArray[0]
    return first.uid === block.uid;
  };
  const noOfType = (type, i, block, array) => {
    const filteredArray = array.filter(b => b.type === type);
    return filteredArray.indexOf(block);
  };

  return (
    <div className="">
      <ManifestoOverlay />
      <div className="overflow-x-hidden">
        {manifestoSlides?.map((block, i, array) => {
          const { type } = block;
          switch (type) {
            case "hero":
              return <ManifestoHero
                {...block}
                first={isFirst(type, i, block, array)}
                last={isLast(type, i, block, array)}
                index={noOfType(type, i, block, array)}
              />;
            case "twoColumns":
              return <ManifestoTwoColumns {...block} />;
            case "sectionLanding":
              return <ManifestoLanding
                {...block}
                first={isFirst(type, i, block, array)}
                index={noOfType(type, i, block, array)}
              />;
            case "threeColumns":
              return <ManifestoThreeColumns {...block} />;
            case "twoColumnsWithPullQuote":
              return <ManifestoThreeColumns {...block} />;
            case "largeText":
              return <ManifestoLargeText {...block} />;
            default:
              return null;
          }
        })}
      </div>
      <ManifestoFooter data={footerSlides} />
    </div>
  )
};

ManifestoContainer.defaultProps = {};

ManifestoContainer.propTypes = {};

export default ManifestoContainer;
