import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";
import { ScrollingContainer, ProcessCard } from "@molecules";
import { useAppState } from "@state/state";

const ProcessServices = ({ heading, processes, stepNumberOnly, noContent }) => {
  const [{ process: _p}, dispatch] = useAppState();
  const { processArray } = _p;

  useEffect(() => {
    dispatch({
      type: "loadProcesses",
      processArray: processes,
      stepNumberOnly,
    });
  }, []);
  return (
    <div className="w-full py-8 md:py-24 lg:py-28 xl:py-32 relative">
      <div className="w-full relative">
        <Container variant="xl">
          {heading && (
            <Text
              variant="xl"
              className="text-white mb-6"
            >
              {heading}
            </Text>
          )}
          <ScrollingContainer className="">
            {processes.map( (process, i) => {
              return (
                <ProcessCard
                  {...process}
                  step={i + 1}
                  key={i}
                  stepNumberOnly={stepNumberOnly}
                  noContent={!process?.processInformation}
                />
              )
            })}
          </ScrollingContainer>
        </Container>
        
      </div>
    </div>
  );
};

ProcessServices.defaultProps = {};

ProcessServices.propTypes = {};

export default ProcessServices;
