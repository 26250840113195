import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";
import { ScrollingContainer, BenefitCard } from "@molecules";

const BenefitsProject = ({ benefits }) => {
  return (
    <div className="w-full py-8 md:py-24 lg:py-28 xl:py-32 relative">
      <div className="w-full relative">
        <div className="relaitve md:absolute top-6 left-12 lg:left-16 z-20 ">
          <Knockout
            variant="h2"
            textColor="blue"
            knockoutColor="black"
          >
            <span>The <br/> Benefits</span>
          </Knockout>
        </div>

        <ScrollingContainer className="pl-24 xl:pl-32">
          {benefits.map( (benefit, i) => {
            return (
              <BenefitCard {...benefit} key={`benefit-${i}`} />
            )
          })}
        </ScrollingContainer>  
      </div>
    </div>
  );
};

BenefitsProject.defaultProps = {};

BenefitsProject.propTypes = {};

export default BenefitsProject;
