import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image, Button, Text, Knockout } from "@atoms";
import { Event, StickyContent } from "@molecules";

const MediaEvents = ({ heading, upcoming, copy, image }) => {
  return (
    <div className="pt-12 pb-20 lg:pt-16 lg:pb-28 relative">
      <div className="bg-black absolute w-full h-full z-0 inset-0 opacity-30">
        <Image
          {...image}
          params={{
            blend: "555",
            "blend-mode": "multiply",
            "blend-alpha": "65",
            sat: -100,
          }}
          fill
        />
      </div>

      <Container
        variant="xl"
        className="flex flex-col md:flex-row z-10 space-y-8 space-x-0 md:space-x-20 md:space-y-0"
      >
        <div>
          <div className="sticky top-4 w-min w-full">
            <Knockout
              variant="h3"
              textColor="bright-pink"
              knockoutColor="black"
              forceWrap
            >
              {heading}
            </Knockout>
          </div>
        </div>

        <div className="relative z-10">
          <ul className="space-y-16 mt-2">
            {upcoming?.map((event, i) => {
              return (
                <li key={i}>
                  <Event {...event} />
                </li>
              );
            })}
            {copy?.map((c, i) => {
              return (
                <li key={i}>
                  <StickyContent {...c} />
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
};

MediaEvents.defaultProps = {};

MediaEvents.propTypes = {};

export default MediaEvents;
