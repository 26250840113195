import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";

const PhilosophyServices = ({ heading, description, link, image }) => {
  return (
    <Container
      variant="2xl"
    >
      <div className="flex flex-col lg:flex-row mb-20">
        <div className="w-full mr-0 mb-12 lg:mb-0 lg:mr-20 max-w-2xl pl-0 md:pl-10">
          <Image {...image} />
        </div>

        <div className="space-y-6 w-full max-w-lg mx-auto">
          <Knockout
            variant="label"
            textColor="black"
            knockoutColor="bright-orange"
          >
            <span>Our Philosophy</span>
          </Knockout>
          <Text
            variant="lg"
            className="text-white"
          >
            {heading}
          </Text>
          <Text
            variant="xs"
            className="text-white"
          >
            {description}
          </Text>
          <Button
            border={false}
            underlineColor="transparent"
            to={link?.url}
            arrowColor="bright-orange"
            arrowSize="small"
          >
            <Text
              variant="sm"
              className="mr-2"
            >
              {link?.text}
            </Text>
          </Button>
        </div>
      </div>
    </Container>
  );
};

PhilosophyServices.defaultProps = {};

PhilosophyServices.propTypes = {};

export default PhilosophyServices;
