import React, { useState, useRef, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Icon, Image, Container, Text, Button, Knockout } from "@atoms";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { AppLink } from "@base";
import gsap from "gsap";
import { isTouchDevice } from "@utils";

const BenefitCard = ({ name, details, image, location, color }) => {
  const [active, setActive] = useState(false);
  const hoverContainer = useRef();

  useEffect(() => {
    if (hoverContainer.current) {
      if (active) {
        gsap.to(hoverContainer.current, { opacity: 1, display: "block", pointerEvents: "auto" });
      } else {
        gsap.to(hoverContainer.current, { opacity: 0, display: "none", pointerEvents: "none" });
      }
    }
  }, [active]);

  return (
    <div 
      className="transition-all relative w-96 h-96 md:w-projects-3/4 md:h-projects-3/4 xl:h-projects xl:w-projects flex-shrink-0"
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <div className="w-full h-full relative">
        <div className="absolute inset-0 z-0">
          <Image {...image} fill />
        </div>

        <div 
          className={classNames("absolute z-20",
            { "top-4 left-4 md:top-6 md:left-6 lg:top-8 lg:left-8" : location === "topLeft" },
            { "top-4 right-4 md:top-6 md:right-6 lg:top-8 lg:right-8" : location === "topRight" },
            { "bottom-4 left-4 md:bottom-6 md:left-6 lg:bottom-8 left-8" : location === "bottomLeft" },
            { "bottom-4 right-4 md:bottom-6 md:right-6 lg:bottom-8 lg:right-8" : location === "bottomRight" },
          )}
        >
          <button
            type="button"
            onMouseEnter={() => { if (!isTouchDevice) setActive(true)}}
            onMouseLeave={() => { if (!isTouchDevice) setActive(false)}}
            onTouchEnd={() => {
              if (active) {
                setActive(false);
              } else {
                setActive(true);
              }
            }}
            aria-expanded={active}
          >
            <Icon
              name="plus"
              className={classNames("w-10 md:w-10 lg:w-14",
                { "text-blue" : color === "blue" },
                { "text-blue-bright" : color === "bright-blue" },
                { "text-bright-pink" : color === "bright-pink" },
                { "text-bright-yellow" : color === "bright-yellow" },
                { "text-bright-orange" : color === "bright-orange" },
              )}
            />
          </button>
        </div>

        <div 
          className={classNames("bg-black max-w-xs w-full p-8 absolute z-20",
            { "top-20 lg:top-28 left-4 md:left-6 lg:left-8" : location === "topLeft" },
            { "top-20 lg:top-28 right-4 md:right-6 lg:right-8" : location === "topRight" },
            { "bottom-20 lg:bottom-28 left-4 md:left-6 lg:left-8" : location === "bottomLeft" },
            { "bottom-20 lg:bottom-28 right-4 md:right-6 lg:right-8" : location === "bottomRight" },
          )} 
          onMouseLeave={() => {
            setActive(false);
          }}
          ref={hoverContainer}
        >
            <div className="mb-2">
              <Knockout
                variant="label"
                textColor="black"
                knockoutColor={color}
                forceWrap={true}
                className="whitespace-normal leading-loose"
              >
                {name}
              </Knockout>
            </div>
            <Text
              variant="xs"
              classNames="text-white"
            >
              {details}
            </Text>
            
          </div>
      </div>
    </div>
  );
};

BenefitCard.defaultProps = {};

BenefitCard.propTypes = {};

export default BenefitCard;