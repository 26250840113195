import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Image, Knockout, Text, Icon } from "@atoms";
import gsap from "gsap";

const ManifestoIntro = ({ firstHeading, secondHeading, keywords, secondKeyword, subhead }) => {
  const [{ manifesto }, dispatch] = useAppState();

  const mainContainer = useRef();
  const firstSlide = useRef();
  const secondSlide = useRef();
  const subheadContainer = useRef();
  const upperLogo = useRef();
  const lowerLogo = useRef();

  const tl = useRef();

  useEffect(() => {
    dispatch({
      type: "startIntro",
    });

    if (
      mainContainer.current && 
      firstSlide.current &&
      secondSlide.current &&
      subheadContainer.current &&
      upperLogo.current &&
      lowerLogo.current &&
      keywords.length > 1) {
      // document.body.classList.add("fixed");

      tl.current = gsap.timeline({ delay: 1 });

      const words = gsap.utils.toArray(".words");
      words.forEach((word, i, array) => {
        const last = i !== array.length - 1;
        const keyframes = last ? 
          [
            {
              duration: .45,
              opacity: 1,
              display: "block",
            }, 
            {
              duration: .45,
              opacity: 0,
              display: "none",
              delay: 1,
            }
          ] :
          [
            {
              duration: .45,
              opacity: 1,
              display: "block",
            }
          ];
        tl.current
          .set(word, { opacity: 0, display: "none" })
          .to(word, { keyframes })
        })

      tl.current
        .set(firstSlide.current, { opacity: 1, display: "block" })
        .set(secondSlide.current, { opacity: 0, display: "none" })
        .set(subheadContainer.current, { opacity: 0, yPercent: 5 })
        .to(firstSlide.current, { opacity: 0, display: "none", duration: 1, delay: 1 })
        .to(secondSlide.current, { opacity: 1, display: "flex", duration: 0.45 })
        .to(subheadContainer.current,{ opacity: 1, duration: 0.45, yPercent: 0 }, "-=0.2")
        .fromTo(
          lowerLogo.current,
          { yPercent: -10, opacity: 0 },
          { yPercent: 0, opacity: 1 },
          "<+0.2"
        )
        .to(mainContainer.current, {
          height: 0,
          minHeight: 0,
          duration: 0.45,
          delay: 2,
          onStart: () => {
            // document.body.classList.remove("fixed");
            dispatch({
              type: "completeIntro",
            });
          },
          onComplete: () => {
            // dispatch({
            //   type: "completeIntro",
            // });
          } 
        })
    }
  }, []);

  return (
    <div className="flex items-center min-h-screen w-full fixed top-0 z-50 bg-black overflow-hidden" ref={mainContainer}>
      <div className="absolute inset-0 m-auto top-1/3 w-full max-w-5xl">
        <div className="" ref={firstSlide}>
          <div className="flex flex-col md:flex-row items-center self-start h-60">
            <Text
              variant="h1"
              className="text-white mr-4 md:mr-6"
            >
              {firstHeading}
            </Text>
            <div className="">
              {keywords?.map((word,i) => {
                return(
                  <div className="words hidden opacity-0" key={i} >
                    <Knockout
                      variant="h1"
                      textColor="black"
                      knockoutColor={word?.color}
                      className=""
                    >
                      {word?.word}
                    </Knockout>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-0 hidden w-full flex flex-col justify-center" ref={secondSlide}>
       <div className="flex flex-col w-auto lg:w-max mx-auto">
        <div
          className="text-center"
          ref={upperLogo}
        >
          <Icon
            name="stamp"
            className="text-blue-bright w-24 lg:w-36"
          />
        </div>

        <div className="mt-20 mb-10 text-center">
          <Knockout
            textColor="black"
            knockoutColor={secondKeyword[0].color}
            className="font-abolition text-6xl md:text-8xl lg:text-manifesto-header px-2 md:px-4"
          >
            <span>{secondHeading} {secondKeyword[0].word}</span>
          </Knockout>
        </div>

        <div
          className="max-w-xl w-full mx-auto text-center opacity-0 px-8 relative mb-6"
          ref={subheadContainer}
        >
          <Text variant="lg">
            {subhead}
          </Text>
        </div>

        <div
          className="text-center opacity-0"
          ref={lowerLogo}
        >
          <Icon
            name="electric"
            className="text-blue-bright w-5 md:w-7"
          />
        </div>
        </div>
      </div>
    </div>
  );
};

ManifestoIntro.defaultProps = {};

ManifestoIntro.propTypes = {};

export default ManifestoIntro;
