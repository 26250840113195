import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Knockout, Icon } from "@atoms";
import { AppLink } from "@base";
import moment from "moment";

const PostCard = ({
  title, 
  description, 
  category, 
  link, 
  image, 
  size,
  author,
  date,
  nudge,
  hideReadOn,
}) => {
  let color;

  switch (category) {
    case "news":
      color = "bright-blue";
      break;
    case "opinion":
      color = "blue";
      break;
    default:
      color = "blue";
      break;
  }

  return (
    <div className="w-full max-w-unset md:max-w-sm col-span-full md:col-span-1 row-span-1 place-self-start">
      <div className="w-full bg-blue relative z-10 h-48">
        <AppLink to={link}>
          <div className="absolute inset-0 w-full h-full">
            <Image {...image} fill />
          </div>
        </AppLink>
      </div>

      <div className="relative z-20 py-4 space-y-4">
        <div>
          <Knockout
            variant="label-sm"
            textColor="black"
            knockoutColor={color} 
          >
            {category}
          </Knockout>
        </div>

        <div>
          <AppLink
            to={link}
            className="no-underline underline-1-transparent"
          >
            <Text
              variant="sm"
            >
              {title}
            </Text>
          </AppLink>
        </div>

        {date && author && (
          <div>
            <Text
              variant="xs"
              className="text-grey"
            >
              <span>{author} &ndash; {moment(date).format("MM/DD/YY")}</span>
            </Text>
          </div>
        )}

        {!date && !author && !hideReadOn &&(
          <div>
            <Button
              to={link}
              border={false}
              arrow={false}
              underlineColor={color} 
            >
              <Text
                variant="label"
              >
                Read on
              </Text>
            </Button>
          </div>
        )}
      </div>

    </div>
  );
};

PostCard.defaultProps = {};

PostCard.propTypes = {};

export default PostCard;
