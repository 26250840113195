import React from "react";
import propTypes from "prop-types";
import { Container, ButtonAlt, Icon, Text } from "@atoms";
import { AppLink } from "@base";
import { HeaderQuery, FooterQuery } from "@staticQueries";
import { MobileNav } from "@molecules";
import classNames from "classnames";
import { useAppState } from "@state/state";

const Header = () => {
  // eslint-disable-next-line no-empty-pattern
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;
  const { nav } = HeaderQuery();
  const { nav: footer } = FooterQuery();
  return (
    <header className="absolute flex items-center tracking-1 top-0 left-0 right-0 h-20 z-50 overflow-hidden">
      <div className="w-full px-5">
        <div className="flex items-center justify-between -mx-3">
          <div className="px-3">
            <AppLink
              to="/"
              className="flex transition duration-200"
            >
              <Icon name="logo" className="h-11 text-blue-bright" fitHeight />
            </AppLink>
          </div>
          <nav className="px-3">
            <ul className="hidden lg:flex -mx-3 lg:-mx-4">
              {nav.map((link, i) => {
                return (
                  <li 
                    className="px-4 lg:px-5"
                    key={`${link.text}-${i}`}
                  >
                    <AppLink
                      activeClassName="underline-5-blue-bright"
                      className="inline-flex transition-shadow duration-200 hover:underline-5-blue-bright"
                      to={link.url}
                    >
                      <Text
                          variant="h5"
                        >
                        {link.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
            <button
              type="button"
              className="flex lg:hidden"
              onClick={() => {
                dispatch({
                  type: "showNav",
                });
              }}
            >
              <Icon name="bars" className={classNames("text-white w-6 h-6", {})} />
            </button>
          </nav>
        </div>
      </div>
      <MobileNav nav={nav} footer={footer} />
    </header>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
