import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import propTypes from "prop-types";
// import { SiteHeader } from "@organisms";
import { useAppState } from "@state/state";
import PageContainer from "./DefaultPageContainer";
import { Header, Footer, ProcessContainer } from "@organisms";
import { loadGTM } from "@utils";

const DefaultLayout = ({ children, path }) => {
  const [{ layout }, dispatch] = useAppState();
  const { theme } = layout;
  const notManifesto = path !== "/manifesto";

  useEffect(() => {
    loadGTM(true);
  }, []);
  // the layout
  return (
    <div id="app_layout" className="bg-black text-white">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/djj7hds.css"/>
      </Helmet>
      {/* <SiteHeader /> */}
      <PageContainer path={path}>
        {notManifesto && (
          <Header />
        )}
        {children}
        {notManifesto && (
          <Footer />
        )}
      </PageContainer>
      <ProcessContainer />
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  path: propTypes.string.isRequired,
};

export default DefaultLayout;
