import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Text, Button, CornerMask, Knockout } from "@atoms";
import { AppLink } from "@base";

const ProjectMaskCard = ({
  heading,
  description,
  image,
  url,
  categories,
  index,
}) => {
  const color = i => {
    if (i % 4 === 0) return "bright-pink";
    if (i % 4 === 1) return "bright-blue";
    if (i % 4 === 2) return "bright-yellow";
    if (i % 4 === 3) return "bright-orange";
    return "bright-pink";
  };
  return (
    <div className="flex w-full relative md:min-h-project-mask overflow-hidden">
      <AppLink to={url} className="group flex">
        <div className="transition-all transform duration-200 w-full h-full bg-blue-bright absolute z-0 filter grayscale group-hover:filter-none group-hover:scale-101.25">
          <Image {...image} params={{ fit: "max" }} fill />
        </div>

        <div className="absolute transition-all inset-0 w-full h-full bg-gradient-to-r from-black z-0 opacity-60 group-hover:opacity-80" />

        <CornerMask />

        <div className="w-full relative z-20 self-start lg:self-center pt-24 pb-8 px-12">
          <div className="relative">
            <Knockout
              variant="h2"
              textColor={color(index)}
              knockoutColor="black"
            >
              {heading}
            </Knockout>
          </div>

          <div className="relative z-20">
            <div className="max-w-md w-full mt-8 space-y-8">
              <Text variant="lg">
                {description}
              </Text>

              {categories && (<ul className="mt-4">
                {categories?.map((category, i) => {
                  return (
                    <li className="inline-block mr-4 mb-2">
                      <Text
                        variant="label"
                        className="text-white"
                      >
                        <span>
                          {category.title}
                          {
                            i !== (categories.length - 1) ? 
                            "," : 
                            "" 
                          }
                        </span>
                      </Text>
                    </li>
                    )
                })}
              </ul>)
              }
            </div>
          </div>
        </div>
       </AppLink>
    </div>
  );
};

ProjectMaskCard.defaultProps = {};

ProjectMaskCard.propTypes = {};

export default ProjectMaskCard;
