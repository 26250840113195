import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Quote } from "@atoms";

const CbQuote = props => {
  return (
    <div className={classNames("my-14", {})}>
      <Quote {...props} small />
    </div>
  );
};

CbQuote.defaultProps = {};

CbQuote.propTypes = {};

export default CbQuote;
