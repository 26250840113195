import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { ButtonAlt, Icon, Text } from "@atoms";
import { AppLink } from "@base";
import gsap from "gsap";

const MobileNav = ({ nav, footer }) => {
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;
  const mobileNav = useRef();
  useEffect(() => {
    if (mobileNav.current) {
      if (showNav) {
        gsap.to(mobileNav.current, { opacity: 1, pointerEvents: "auto" });
      } else {
        gsap.to(mobileNav.current, { opacity: 0, pointerEvents: "none" });
      }
    }
  }, [showNav]);
  return (
    <div
      ref={mobileNav}
      className={classNames(
        "fixed opacity-0 pointer-events-none inset-0 bg-black flex flex-col justify-start text-base opacity-0 lg:hidden w-full",
        {}
      )}
    >
      <div className="absolute top-0 p-6 text-white right-0 z-30">
        <button
          type="button"
          onClick={() => {
            dispatch({ type: "hideNav" });
          }}
        >
          <Icon
            name="close"
            className={classNames("w-6 h-6 hover:text-orange", {})}
          />
        </button>
      </div>
      <div className="px-5 py-3 mt-1.5 mb-6 text-white relative z-20">
        <AppLink to="/">
          <Icon name="logo" className="h-11 text-blue-bright" fitHeight />
        </AppLink>
      </div>
      
      <div className="flex flex-col content-between justify-between h-full">
        <div>
          <ul className="flex flex-col justify-center space-y-4 mb-6 px-6 relative z-20">
            {nav.map((link, i) => {
              return (
                <li
                  key={`${link.text}-${i}`}
                >
                  <AppLink
                    to={link.url}
                    onClick={() => {
                      dispatch({
                        type: "hideNav",
                      });
                    }}
                    key={`${link.text}-${i}`}
                  >
                    <Text
                      variant="h3"
                    >
                    {link.text}
                  </Text>
                  </AppLink>
                </li>
              );
            })}
          </ul>

          <ul className="flex flex-col justify-center space-y-4 px-6 relative z-20">
            {footer.map((link, i) => {
              return (
                <li
                  key={`${link.text}-${i}`}
                >
                  <AppLink
                    to={link.url}
                    onClick={() => {
                      dispatch({
                        type: "hideNav",
                      });
                    }}
                    key={`${link.text}-${i}`}
                  >
                    <Text
                      variant="label"
                      className="text-blue"
                    >
                    {link.text}
                  </Text>
                  </AppLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

MobileNav.defaultProps = {};

MobileNav.propTypes = {};

export default MobileNav;
