import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { CbForm, CbCta, Share, SidebarRelatedArticles } from "@molecules";

const Sidebar = ({ blocks }) => {
  return (
    <div>
      <ul className="flex flex-col">
        {blocks?.map(block => {
          switch (block?.type) {
            case "relatedArticles":
              return (
                <li>
                  <SidebarRelatedArticles {...block} />
                </li>
              );
            case "relatedCaseStudies":
              return (
                <li>
                  <SidebarRelatedArticles {...block} />
                </li>
              );
            case "cta":
              return (
                <li className="mb-6">
                  <CbCta {...block} />
                </li>
              );
            case "form":
              return (
                <li className="mb-6">
                  <CbForm {...block} sidebar />
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    </div>
  );
};

Sidebar.defaultProps = {};

Sidebar.propTypes = {};

export default Sidebar;
