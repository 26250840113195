import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Knockout, Text, Button, Container, CornerMask } from "@atoms";

const TextWithImage = ({ heading, subhead, description, link, image, right }) => {
  return (
    <div className="flex flex-col md:flex-row space-x-0 space-y-8 md:space-x-8 md:space-y-0 pb-8">
      <div className="w-full md:w-1/2 flex flex-col py-8 self-center">
        <div className="max-w-xl w-full self-center space-y-6 px-4">
          <div>
            <Knockout
              variant="h4"
              textColor="black"
              knockoutColor="blue"
            >
              {heading}
            </Knockout>
          </div>
          <div className="space-y-4">
            <Text variant="lg">
              {subhead}
            </Text>
            <Text variant="body">
              {description}
            </Text>
          </div>
          {link?.url && (
            <div className="pt-4">
              <Button to={link?.url}>
                <Text variant="body">
                  {link?.text}
                </Text>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={classNames(
        "relative w-full md:w-1/2 order-first",
        { "md:order-1" : right },
        { "md:order-first" : !right },
      )}>
        <CornerMask flip={right} />
        <Image {...image} />
      </div>
    </div>
  );
};

TextWithImage.defaultProps = {};

TextWithImage.propTypes = {};

export default TextWithImage;