import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Knockout } from "@atoms";

const ErrorCard = ({
  title, 
  description,
  link,
  image,
}) => {
  return (
    <div className="w-full relative max-w-unset lg:max-w-md col-span-full sm:col-span-3 lg:col-span-2 lg:row-span-1">
      
      <div className="w-full bg-blue relative z-10 h-48">
        <div className="absolute inset-0 w-full h-full">
          <Image {...image} fill />
        </div>
      </div>

      <div className="mt-2 bg-black py-4 space-y-6 relative">
        <div>
          <Text
            variant="lg"
          >
            {title}
          </Text>
        </div>

        <div>
          <Text
            variant="sm"
          >
            {description}
          </Text>
        </div>

        <div>
          <Button
            to={link?.url}
            border={false}
            arrow={false}
            underlineColor="blue"
          >
            <Text
              variant="label"
            >
              {link?.text}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

ErrorCard.defaultProps = {};

ErrorCard.propTypes = {};

export default ErrorCard;
