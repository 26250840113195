import React, { useState } from "react";
import propTypes from "prop-types";
import { Quote, Container, Image } from "@atoms";

const QuoteContainer = ({ quote }) => {
  return (
    <div className="bg-bright-yellow pt-12 pb-20 lg:pt-16 lg:pb-28 overflow-hidden">
      <Container
        variant="xl"
        className="flex flex-col space-y-12"
      >
        <div>
          <Quote {...quote} />
        </div>
      </Container>
    </div>
  );
};

QuoteContainer.defaultProps = {};

QuoteContainer.propTypes = {};

export default QuoteContainer;
