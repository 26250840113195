const reducer = (state, action) => {
  switch (action.type) {
    case "openProccess":
      return {
        ...state,
        process: true,
        content: action.content,
        location: action.location,
        containerColor: action.containerColor,
      };
    case "closeProcess":
      return {
        ...state,
        process: false,
      };
    case "clearProcessContent":
      return {
        ...state,
        process: false,
        content: false,
        location: false,
        containerColor: null,
      };
    case "activeProcess":
      return {
        ...state,
        content: action.content,
        containerColor: action.containerColor,
      };
    case "loadProcesses":
      return {
        ...state,
        processArray: action.processArray,
        stepNumberOnly: action.stepNumberOnly,
      };
    default:
      return state;
  }
};

const initialState = {
  process: false,
  content: false,
  location: false,
  containerColor: "blue",
  processArray: false,
  stepNumberOnly: false,
};

const process = {
  reducer,
  initialState,
};

export default process;
