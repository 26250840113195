import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Text, Button, Knockout, Icon } from "@atoms";
import { ProcessItem } from "@molecules";
import { useAppState } from "@state/state";
import gsap from "gsap";

const ProcessContent = ({ heading, description, processDescription, processInformation, step, color, stepNumberOnly }) => {
  const tl = useRef();
  const colorCover = useRef();
  const blackCover = useRef();
  const [{ process: _p}, dispatch] = useAppState();
  const { process, content, restartAnimation } = _p;

  const stepNumber = step < 10 ? `0${step}` : step;
  const stepText = `Step ${step}`;

  useEffect(() => {
    if (colorCover.current) {
      tl.current = gsap
        .timeline({ paused: true})
        .set(colorCover.current, { height: "100%" })
        .set(blackCover.current, { height: "100%" })
        .to(
          colorCover.current,
          { 
            height: "0%",
            duration: 0.45,
            ease: "power3.in",
          },
          "+=0.4")
        .to(
          blackCover.current,
          { 
            height: "0%",
            duration: 0.35,
            ease: "power3.in",
          },
          "-=0.3");
    }
  }, []);

  useEffect(() => {
    if (process) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [process]);

  useEffect(() => {
    tl.current.restart();
  }, [content]);

  const leftColumn = processInformation?.filter((a, i) => i % 2 === 0);
  const rightColumn = processInformation?.filter((a, i) => i % 2 === 1);

  return (
    <div>
      <div className="w-full h-full flex flex-col lg:flex-row items-start overflow-hidden">
        <div className="pt-1 pb-8 mr-0 lg:mr-32 relative flex-1">
          <div className="space-y-8">
            <Knockout
              variant="label"
              textColor={color}
              knockoutColor="black"
            >
              <span>{ stepNumberOnly ? stepNumber : stepText }</span>
            </Knockout>
            <Text
              variant="h2"
              className="text-black w-full xl:w-max"
            >
              {heading}
            </Text>
            <Text
              variant="body"
              className="max-w-md w-full flex-shrink-0 text-black"
            >
              {processDescription || description}
            </Text>
          </div>
          <div className="h-full w-full absolute inset-x-0 bottom-0 bg-black" ref={blackCover} />
          <div className={
            classNames("h-full w-full absolute inset-x-0 bottom-0",
              { "bg-blue" : color === "blue" },
              { "bg-blue-bright" : color === "bright-blue" },
              { "bg-bright-pink" : color === "bright-pink" },
              { "bg-bright-yellow" : color === "bright-yellow" },
              { "bg-bright-orange" : color === "bright-orange" },
            )} 
            ref={colorCover} />
        </div>

        <div className="">
          <div 
            className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-4 md:gap-y-8 items-start"
          >
            <div className="hidden lg:block">
              {leftColumn?.map((info, i) => {
                return (
                  <ProcessItem info={info} color={color} delay={(0.125 * i)} index={i} key={i}/>
                )
              })}
            </div>
            <div className="hidden lg:block">
              {rightColumn?.map((info, i) => {
                return (
                  <ProcessItem info={info} color={color} delay={(0.125 * i)} index={i} key={i}/>
                )
              })}
            </div>
            <div className="block lg:hidden">
              {processInformation?.map((info, i) => {
                return (
                  <ProcessItem info={info} color={color} delay={(0.125 * i)} index={i} key={i}/>
                )
              })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

ProcessContent.defaultProps = {};

ProcessContent.propTypes = {};

export default ProcessContent;