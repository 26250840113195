import moment from "moment";
import resolveImage from "./resolveImage";
import resolveCB from "./resolveCB";
import resolveMeta from "./resolveMeta";
import resolveSidebar from "./resolveSidebar";

const resolveArticle = article => {
  if (article) {
    const {
      metaDescription,
      metaTitle,
      metaImage,
      title,
      uid,
      descriptor0,
      slug,
      url,
      authors,
      categories,
      postDate,
      heroImage,
      contentBuilder,
      sidebar,
    } = {
      ...article,
    };

    const byline = `${authors?.[0].title} – ${moment(postDate).format("MM/DD/YY")}`;
    return {
      url,
      uid,
      slug,
      title,
      meta: resolveMeta({
        title,
        metaTitle,
        metaImage,
        metaDescription,
        slug,
        url,
      }),
      hero: {
        label: categories?.[0].title,
        heading: title,
        description: descriptor0,
        image: resolveImage(heroImage),
        byline,
      },
      contentBuilder: resolveCB(contentBuilder),
      sidebar: resolveSidebar(sidebar),
    };
  }
  return null;
};

export default resolveArticle;
