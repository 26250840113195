import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Button, Knockout, Icon } from "@atoms";
import { AppLink } from "@base";

const ServiceCard = ({ label, heading, items, link, i: _i }) => {
  const colors = {
    0: "bright-pink",
    1: "bright-blue",
  }
  return (
    <AppLink
        to={link?.url}
        className="block max-w-xl w-full"
      >
      <div className={classNames("transition-all max-w-xl w-full border-3 pt-16 px-14 pb-12 space-y-8 group",
          { "border-bright-pink hover:bg-bright-pink" : _i === 0 },
          { "border-blue-bright hover:bg-blue-bright" : _i === 1 },
        )}
      >

        <Text
          variant="label-lg"
          className={classNames("",
            { "text-bright-pink group-hover:text-black" : _i === 0 },
            { "text-blue-bright group-hover:text-black" : _i === 1 },
          )}
        >
          {label}
        </Text>
        <Text
          variant="h4"
          className="text-white group-hover:text-black"
        >
          {heading}
        </Text>
        <ul>
          {items.map((list, i) => {
            return (
              <li className="flex items-start md:items-center mb-6" key={i}>
                <Icon name="electric"
                  className={classNames(
                    "h-5 mr-3 mt-1 md:mt-0",
                    { "text-bright-pink group-hover:text-black" : _i === 0 },
                    { "text-blue-bright group-hover:text-black" : _i === 1 },
                  )}
                  fitHeight
                />
                <Text
                  variant="body"
                  className="text-white group-hover:text-black"
                >
                  {list.listItem}
                </Text>
              </li>
            )
          })}
        </ul>
        <Button
          border={false}
          arrow={false}
          underlineColor={colors[_i]}
          className={`group-hover:underline-1-black`}
        >
          <Text
            variant="label-lg"
            className={classNames("",
              { "text-white group-hover:text-black" : _i === 0 },
              { "text-white group-hover:text-black" : _i === 1 },
            )}
          >
            {link?.text}
          </Text>
        </Button>
      </div>
    </AppLink>
  );
};

ServiceCard.defaultProps = {};

ServiceCard.propTypes = {};

export default ServiceCard;
