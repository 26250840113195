import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text, Button, Icon } from "@atoms";

const Field = ({
  label,
  name,
  required,
  placeholder,
  type,
  textarea,
  hideLabel,
  onChange,
  light
}) => {
  return (
    <div className={classNames(
      "flex flex-1",
      { "flex-col" : type === "text" || textarea },
      { "flex-row" : type === "checkbox" },
    )}>
      <label htmlFor={name} className={classNames(
        "",
        { "mb-2" : type === "text" || textarea },
        { "sr-only" : hideLabel},
        { "mx-4 cursor-pointer relative" : type === "checkbox" },
      )}>
        <Text variant="sm">{label}</Text>
      </label>
      {!textarea && type !== "checkbox" && (
        <input
          type={type}
          name={name}
          id={name}
          required={required}
          className={classNames("p-2 w-full rounded-none",
            { "bg-black border-white text-white border-3": !light },
            { "bg-white border-grey text-grey border-2": light },
          )}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
      {!textarea && type === "checkbox" && (
        <div className="relative order-first self-center">
          <input
            type={type}
            name={name}
            id={name}
            required={required}
            className="bg-black border-white text-white p-2 appearance-none w-6 h-6 checked:bg-white mr-4 border-2 rounded-none cursor-pointer"
            placeholder={placeholder}
            onChange={onChange}
          />
          <Icon name="check" className="text-black w-4 h-4 absolute z-0 inset-1 pointer-events-none" />
        </div>
      )}
      {textarea && (
        <textarea
          name={name}
          id={name}
          required={required}
          className="max-w-xl w-full bg-black border-white border-3 text-white py-2 px-2 h-40 rounded-none"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

Field.defaultProps = {};
Field.propTypes = {};

export default Field;
