import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Button, Text, Knockout, Icon } from "@atoms";
import { ServiceCard } from "@molecules";

const WorkingWithUsServices = ({ heading, subhead, services, image }) => {
  return (
    <div className="relative">
      <div className="bg-black absolute w-full h-full inset-0 z-0">
        <Image 
          {...image}
          params={{ 
            blend: "070707",
            "blend-mode": "multiply",
            "blend-alpha": "75",
            sat: -100,
          }} 
          fill
        />
      </div>
      <Container
        variant="xl"
        className="relative z-10"
      >
        <div className="relative z-10 py-8 md:py-24 lg:py-28 xl:py-32 mx-auto">
          <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-8 md:space-x-24 justify-center">
            <div className="flex-shrink-0">
              <Knockout
                variant="h3"
                textColor="black"
                knockoutColor="bright-yellow"
              >
                {heading}
              </Knockout>
            </div>
            
            <div className="max-w-lg w-full">
              <Text
                variant="lg"
              >
                {subhead}
              </Text>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-col lg:flex-row mt-16 space-x-0 space-y-6 lg:space-x-6 lg:space-y-0">
              {services.map( (service, i) => {
                return (
                  <ServiceCard {...service} i={i} key={i} />
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

WorkingWithUsServices.defaultProps = {};

WorkingWithUsServices.propTypes = {};

export default WorkingWithUsServices;
