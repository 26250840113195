import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Knockout } from "@atoms";
import { AppLink } from "@base";

const ProjectCard = ({ project, image, link, i }) => {
  const color = i => {
    if (i % 4 === 0) return "bright-pink";
    if (i % 4 === 1) return "bright-blue";
    if (i % 4 === 2) return "bright-yellow";
    if (i % 4 === 3) return "bright-orange";
    return "bright-pink";
  };
  return (
    <div className="transition-all relative w-96 h-96 lg:w-projects-3/4 lg:h-projects-3/4 3xl:h-projects 3xl:w-projects bg-blue-bright flex-shrink-0">
      <AppLink
        to={link?.url}
        title={link?.text || project}
        className="w-full h-full"
      >
        <div className="w-full h-full relative">
          <div className="absolute inset-0 z-0">
            <Image {...image} fill />
          </div>

          <div className="absolute -bottom-1 left-0 z-10 w-full text-left">
            <Knockout
              variant="h4"
              textColor={color(i)}
              knockoutColor="black"
              forceWrap
            >
              {project}
            </Knockout>
          </div>
        </div>
      </AppLink>
    </div>
  );
};

ProjectCard.defaultProps = {};

ProjectCard.propTypes = {};

export default ProjectCard;