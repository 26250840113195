import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Knockout, Container, Button } from "@atoms";
import { Share } from "@molecules";

const ManifestoShare = ({ heading, subhead, button, url }) => {
  return (
    <div className="max-w-xs w-full flex-1 border-3 border-bright-pink py-6 flex justify-center">
      <div className="px-6">
        <div className="flex flex-col text-center">
          <div className="mb-3">
            <Knockout
              variant="h5"
              knockoutColor="bright-pink"
              textColor="black"
            >
              {heading}
            </Knockout>
          </div>

          <Text variant="xs" className="mb-4">
            {subhead}
          </Text>

          <div className="flex justify-center mb-14">
            <Share url={url} color="text-bright-pink" small hideShare />
          </div>
        </div>

        <div className="flex flex-col text-center">
          <div>
            <Button
              to={button?.url}
              arrowSize="sm"
              arrowColor="bright-pink"
              border={false}
              underlineColor="transparent"
            >
              <Text variant="sm" className="mr-3">{button?.text}</Text>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ManifestoShare.defaultProps = {};
ManifestoShare.propTypes = {};

export default ManifestoShare;