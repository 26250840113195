import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Container, Text, Button, Knockout, Icon } from "@atoms";
import { useAppState } from "@state/state";
import { removeBreaks } from "@utils";
import gsap from "gsap";

const ProcessCard = ({ heading, description, step, color, stepNumberOnly, noContent }) => {
  const [isOpen, setOpenProccess] = useState(false);
  const [{ process: _p }, dispatch] = useAppState();
  const { process } = _p;

  const tl = useRef();
  const cardContainer = useRef();
  const cardContent = useRef();
  
  const stepNumber = step < 10 ? `0${step}` : step;
  const stepText = `Step ${step}`;

  const getTilePosition = element => {
    let box = element.getBoundingClientRect();

    return {
      top: Math.round(box.top),
      left: Math.round(box.left),
      height: box.height,
      width: box.width,
    };
  }

  useEffect(() => {
    if (cardContainer.current && cardContent.current) {
      tl.current = gsap
        .timeline({ paused: true})
        .set(cardContent.current, { opacity: 1 })
        .to(cardContent.current, {
          opacity: 0,
          duration: 0.15,
          onComplete: () => {
          dispatch({
            type: "openProccess",
            content: step - 1,
            location: getTilePosition(cardContainer.current),
            containerColor: color,
          });
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!process) {
      tl.current.reverse();
    }
  }, [process]);

  if (noContent) {
    return (
      <div
        className={classNames(
          "transition-all text-left relative flex-shrink-0 px-8 md:px-12 py-6 md:py-10 group w-full max-w-sm md:max-w-lg border-4",
          { "border-blue hover:bg-blue" : color === "blue"  },
          { "border-blue-bright hover:bg-blue-bright" : color === "bright-blue"  },
          { "border-bright-pink hover:bg-bright-pink" : color === "bright-pink"  },
          { "border-bright-yellow hover:bg-bright-yellow" : color === "bright-yellow"  },
          { "border-bright-orange hover:bg-bright-orange" : color === "bright-orange"  },
        )}
        ref={cardContainer}
      >
        <div className="w-full h-full flex group content-start" ref={cardContent}>
          <div className="space-y-8 self-start">
            <Knockout
              variant="label"
              textColor="black"
              knockoutColor={color}
              className={classNames("group-hover:bg-black",
                { "group-hover:text-blue" : color === "blue"  },
                { "group-hover:text-blue-bright" : color === "bright-blue"  },
                { "group-hover:text-bright-pink" : color === "bright-pink"  },
                { "group-hover:text-bright-yellow" : color === "bright-yellow"  },
                { "group-hover:text-bright-orange" : color === "bright-orange"  },
              )} 
            >
              <span>{ stepNumberOnly ? stepNumber : stepText }</span>
            </Knockout>
            <Text
              variant="h3"
              className="text-white group-hover:text-black"
            >
              {heading}
            </Text>
            <Text
              variant="body"
              className="max-w-md w-full flex-shrink-0 group-hover:text-black"
            >
              {description}
            </Text>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <button
        className={classNames(
          "transition-all text-left relative flex-shrink-0 px-8 md:px-12 py-6 md:py-10 group w-full max-w-sm md:max-w-lg cursor-pointer border-4",
          { "border-blue hover:bg-blue" : color === "blue"  },
          { "border-blue-bright hover:bg-blue-bright" : color === "bright-blue"  },
          { "border-bright-pink hover:bg-bright-pink" : color === "bright-pink"  },
          { "border-bright-yellow hover:bg-bright-yellow" : color === "bright-yellow"  },
          { "border-bright-orange hover:bg-bright-orange" : color === "bright-orange"  },
        )}
        aria-label={`Open ${removeBreaks(heading)} process card`}
        ref={cardContainer}
        onClick={() => {
          tl.current.play();
        }}
      >
        <div className="w-full h-full flex group content-start" ref={cardContent}>
          <div className="space-y-8 self-start">
            <Knockout
              variant="label"
              textColor="black"
              knockoutColor={color}
              className={classNames("group-hover:bg-black",
                { "group-hover:text-blue" : color === "blue"  },
                { "group-hover:text-blue-bright" : color === "bright-blue"  },
                { "group-hover:text-bright-pink" : color === "bright-pink"  },
                { "group-hover:text-bright-yellow" : color === "bright-yellow"  },
                { "group-hover:text-bright-orange" : color === "bright-orange"  },
              )} 
            >
              <span>{ stepNumberOnly ? stepNumber : stepText }</span>
            </Knockout>
            <Text
              variant="h3"
              className="text-white group-hover:text-black"
            >
              {heading}
            </Text>
            <Text
              variant="body"
              className="max-w-md w-full flex-shrink-0 group-hover:text-black"
            >
              {description}
            </Text>
            <Icon 
              name="plus"
              className=""
              className={classNames("w-6 group-hover:text-black",
                { "text-blue" : color === "blue"  },
                { "text-blue-bright" : color === "bright-blue"  },
                { "text-bright-pink" : color === "bright-pink"  },
                { "text-bright-yellow" : color === "bright-yellow"  },
                { "text-bright-orange" : color === "bright-orange"  },
              )} 
            />
          </div>
        </div>
      </button>
  );

  }
};

ProcessCard.defaultProps = {};

ProcessCard.propTypes = {};

export default ProcessCard;