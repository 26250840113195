import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Knockout, Container, Button } from "@atoms";

const ManifestoContact = ({ heading, subhead, contact, work }) => {
  return (
    <div className="max-w-xs w-full flex-1 border-3 border-blue-bright py-6 flex justify-center">
      <div className="px-6">
        <div className="flex flex-col text-center">
          <div className="mb-3">
            <Knockout
              variant="h5"
              knockoutColor="bright-blue"
              textColor="black"
            >
              {heading}
            </Knockout>
          </div>

          <Text variant="xs" className="mb-12">
            {subhead}
          </Text>
        </div>

        <div className="flex flex-col text-center">
          <div>
            <Button
              to={contact?.url}
              arrowSize="sm"
              arrowColor="bright-blue"
              border={false}
              underlineColor="transparent"
            >
              <Text variant="sm" className="mr-3">{contact?.text}</Text>
            </Button>
          </div>

          <div>
            <Button
              to={work?.url}
              arrowSize="sm"
              arrowColor="bright-blue"
              border={false}
              underlineColor="bright-blue"
              arrow={false}
            >
              <Text variant="label-sm">{work?.text}</Text>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ManifestoContact.defaultProps = {};
ManifestoContact.propTypes = {};

export default ManifestoContact;