module.exports = {
  purge:
    process.env.NODE_ENV === "development"
      ? false
      : {
          mode: "all",
          content: ["./src/**/*.{js,svg}"],
          options: {
            safelist: ["object-contain", "object-cover"],
          },
        },
  theme: {
    fontFamily: {
      abolition: ["abolition", "sans-serif"],
      antarctican: ["antarctican-mono", "sans-serif"],
      sans: ["neue-haas-grotesk-display", "sans-serif"],
    },
    fontSize: {
      // Seperate them by typeface because
      // there's very little overlap with sizes
      "manifesto-header": "12rem",
      "abolition-h1": "8.3rem", 
      "abolition-h2": "6.1rem",
      "abolition-h3": "4.44rem",
      "abolition-h4": "3.8rem",
      "abolition-h5": "2.7rem",
      "antarctican-h1": "1.3rem",
      "antarctican-h2": "1.1rem",
      "antarctican-h3": "0.8rem",
      "neue-h1": "3.3rem",
      "neue-h2": "2.6rem",
      "neue-h3": "2rem",
      "neue-h4": "1.5rem",
      "neue-h5": "1.3rem",
      "neue-h6": "1rem",
      // Keep defaults
      xxs: ".625rem",
      xs: ".75rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1.5xl": "1.33rem",
      "2xl": "1.5rem",
      "2.5xl": "1.75rem",
      "3xl": "2rem",
      "3.5xl": "2.25rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      h1: "5rem",
      h2: "4rem",
      h3: "3rem",
      h4: "2rem",
      h5: "1.75rem",
      inherit: "inherit",
    },
    screens: {
      xxs: { max: "350px" }, // for super small screens
      sm: "700px", // bigger than most phones
      md: "850px",
      lg: "1200px",
      xl: "1400px", // larger than 15" macbook pro
      "2xl": "2000px",
    },
    colors: {
      black: {
        DEFAULT: "#070707",
        medium: "#353535",
      },
      white: {
        DEFAULT: "#FFFFFF",
      },
      grey: {
        DEFAULT: "#888888",
      },
      blue: {
        DEFAULT: "#1E98F4",
        dark: "#0E48A8",
        bright: "#29D4FF",
      },
      "bright-yellow": {
        DEFAULT: "#E6E737",
      },
      "bright-pink": {
        DEFAULT: "#F05FE5",
      },
      "bright-orange": {
        DEFAULT: "#FCC103",
      },
      red: {
        DEFAULT: "red",
      },
      transparent: {
        DEFAULT: "transparent",
      },
    },
    extend: {
      maxWidth: {
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "44rem",
        "3xl": "52rem",
        "4xl": "60rem",
        "5xl": "68rem",
        "6xl": "76rem",
        "6.5xl": "80rem",
        "7xl": "84rem",
        "8xl": "92rem",
        full: "100%",
      },
      transitionProperty: {
        default:
          " background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
      },
      opacity: {
        10: ".1",
        15: ".15",
        20: ".2",
        30: ".3",
        35: ".35",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".8",
        90: ".9",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
        "project-mask": "655px",
      },
      height: {
        25: "25%",
        "1/2-screen": "50vh",
        hero: "988px",
        projects: "800px",
        "projects-3/4": "600px",
        "mobile-scroll": "500px",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
        mask: "586px",
        projects: "810px",
        "projects-3/4": "607px",
      },
      padding: {
        lg: "5rem",
        xl: "7.22rem",
        "2xl": "8.16rem",
      },
      borderWidth: {
        3: "3px",
        12: "12px",
      },
      scale: {
        101.25: "1.0125",
      },
      backgroundSize: {
        '2/1': '200%',
      },
      backgroundImage: {
        'hard-stop': 'linear-gradient(90deg,var(--tw-gradient-from) 50%,var(--tw-gradient-to,rgba(7,7,7,0)) 50%)',
      },
      transitionDuration: {
        650: '650ms',
      },
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
      textColor: theme => theme('colors'),
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive"],
    fill: ["hover", "group-hover", "responsive"],
    scale: ["hover", "group-hover", "responsive"],
    translate: ["hover", "group-hover", "responsive"],
    boxShadow: ["hover", "group-hover", "responsive"],
    animation: ["hover", "group-hover", "responsive"],
    textColor: ["responsive", "hover", "focus", "active", "group-hover"],
    width: ["responsive", "hover", "group-hover"],
    filter: ["responsive", "hover", "group-hover", "focus"],
    backgroundColor: ['responsive', 'dark', 'group-hover', 'focus-within', 'hover', 'focus', "checked"],
  },
};
