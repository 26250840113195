import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container, Knockout } from "@atoms";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ManifestooThreeColumns = ({ heading, subhead, copy, pullQuote, color, keyword, dontSetFooter }) => {
  const container = useRef();
  const [{ manifestoo }, dispatch] = useAppState();
  const [inView, setInView] = useState(false);

  const split = word => {
    return word.split(" ").map(word => `<span class="inline-block px-1 md:px-2">${word}</span>`).join("");
  };

  useEffect(() => {
    if (container.current) {
      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        invalidateOnRefresh: true,
        onEnterBack: () => {
          if (dontSetFooter) {
            dispatch({
              type: "setFooterColor",
              footerColor: "black",
            });
          }
        },
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "top bottom",
        invalidateOnRefresh: true,
        onEnter: () => {
          dispatch({
            type: "setFooterColor",
            footerColor: color,
          });
        },
      });

      ScrollTrigger.create({
        trigger: container.current,
        start: "center bottom-=25%",
        invalidateOnRefresh: true,
        onEnter: () => setInView(true),
      });
    }
  }, []);

  return (
    <div ref={container} className="py-12 lg:py-20">
      <Container
        variant="2xl"
        className="flex py-24 min-h-2/3"
      >
        <div className="w-full">
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 justify-between">
            <div className="max-w-md w-full">
              <Text variant="lg" className="mb-8">
                {heading}
              </Text>

              <Text variant="body">
                {subhead}
              </Text>
            </div>

            {copy && (
              <div className="max-w-2xl pl-0 md:pl-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
                <Text variant="sm" className={classNames("manifesto-text ",
                  { "manifesto-blue": color === "blue" },
                  { "manifesto-bright-blue": color === "bright-blue" },
                  { "manifesto-bright-orange": color === "bright-orange" },
                  { "manifesto-bright-yellow": color === "bright-yellow" },
                  { "manifesto-bright-pink": color === "bright-pink" },
                  { "animate": inView },
                )}>
                  {copy?.leftColumn}
                </Text>
                <Text variant="sm" className={classNames("manifesto-text ",
                  { "manifesto-blue": color === "blue" },
                  { "manifesto-bright-blue": color === "bright-blue" },
                  { "manifesto-bright-orange": color === "bright-orange" },
                  { "manifesto-bright-yellow": color === "bright-yellow" },
                  { "manifesto-bright-pink": color === "bright-pink" },
                  { "animate": inView },
                )}>
                  {copy?.rightColumn}
                </Text>
              </div>
            )}

            {pullQuote && (
              <div className="max-w-xl pl-0 md:pl-6 w-full self-center">
                <Knockout
                  variant="manifesto-h2"
                  // knockoutColor={color}
                  textColor="black"
                  forceWrap={true}
                  noPadding={true}
                  className={classNames(
                    `decoration-slice transition-all duration-650 ease-out bg-2/1 bg-hard-stop bg-gradient-to-l to-black`,
                    { "from-blue-bright": color === "bright-blue" },
                    { "from-bright-orange": color === "bright-orange" },
                    { "from-bright-yellow": color === "bright-yellow" },
                    { "from-bright-pink": color === "bright-pink" },
                    { "bg-right": !inView },
                    { "bg-left": inView },
                  )}
                >
                  {split(pullQuote)}
                </Knockout>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

ManifestooThreeColumns.defaultProps = {};

ManifestooThreeColumns.propTypes = {};

export default ManifestooThreeColumns;
