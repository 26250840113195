import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Cta } from "@molecules";

const CbCta = cta => {
  return (
    <Cta {...cta} />
  );
};

CbCta.defaultProps = {};

CbCta.propTypes = {};

export default CbCta;
