import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Text, Button, Knockout, Icon } from "@atoms";
import { AppLink } from "@base";
import moment from "moment";

const AnnouncementCard = ({
  place,
  heading,
  description,
  link,
  color,
  image,
  size,
  nudge,
}) => {
  let headerSize;
  let labelSize;

  switch (size) {
    case "lg":
      headerSize = "xl";
      labelSize = "label";
      break;
    case "sm":
      headerSize = "sm";
      labelSize = "label-sm";
      break;
    default:
      headerSize = "body";
      labelSize = "label-lg";
      break;
  }

  return (
    <div className="w-full place-self-start">
      <AppLink
        to={link}
        className="no-underline underline-1-transparent group"
      >
        <div className={classNames(
          "w-full bg-blue relative z-10",
          { "h-60" : !size },
          { "h-96" : size === "lg" },
          { "h-48" : size === "sm" },
        )}>
          <div className="absolute inset-0 w-full h-full z-10">
            <Image {...image} fill />
          </div>
          <div className="absolute -bottom-1 left-0 z-20">
            <Knockout
              variant="h5"
              knockoutColor="black"
              textColor={color}
              forceWrap
            >
              {place}
            </Knockout>
          </div>
        </div>

        <div className={classNames(
          "bg-black relative z-20",
          { "p-8 space-y-6" : size === "lg" || !size },
          { "py-4 space-y-4" : size === "sm" },
        )}>
          <div>
            <Text
              variant={headerSize}
              className="text-lg"
            >
              {heading}
            </Text>
          </div>
          {description && (
            <div>
              <Text variant="xs">
                {description}
              </Text>
            </div>
          )}
          <div>
            <Icon
              name="inverseArrow"
              className={classNames(
                "transform group-hover:translate-x-1 transition-all duration-300 delay-150",
                { "text-blue hover:text-blue" : color === "blue" },
                { "text-blue-bright hover:text-blue-bright" : color === "bright-blue" },
                { "text-bright-pink hover:text-bright-pink" : color === "bright-pink" },
                { "text-bright-yellow hover:text-bright-yellow" : color === "bright-yellow" },
                { "text-bright-orange hover:text-bright-orange" : color === "bright-orange" },
              )}
            />
          </div>
        </div>
      </AppLink>
    </div>
  );
};

AnnouncementCard.defaultProps = {};

AnnouncementCard.propTypes = {};

export default AnnouncementCard;
