import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Form } from "@molecules";

const CbForm = form => {
  return (
    <Form {...form} />
  );
};

CbForm.defaultProps = {};

CbForm.propTypes = {};

export default CbForm;
