import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Video, Text } from "@atoms";

const CbVideo = ({ videoUrl, caption, fluid }) => {
  return (
    <div className={classNames("flex my-6 mb-12", {})}>
      <div className={classNames("w-full", 
        { "max-w-lg" : !fluid},
      )}>
        {videoUrl && (
          <div className="shadow-xl">
            <Video url={videoUrl} />
          </div>
        )}
        <div className="pt-3">
          <Text variant="base" className="text-sm text-blue">
            {caption}
          </Text>
        </div>
      </div>
    </div>
  );
};

CbVideo.defaultProps = {};

CbVideo.propTypes = {};

export default CbVideo;
