import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container } from "@atoms";
import { PostCard } from "@molecules";
import { resolveImage } from "@dataResolvers";
import { InView } from "@designbycosmic/cosmic-react-common-components";

const PostsContainer = ({ posts }) => {
  const limit = 5;
  const [page, setPage] = useState(1);
  const [dontGetPosts, setDontGetPosts] = useState(false);
  const offset = limit * page;

  const getMorePosts = async () => {
    const query = `
      query($offset: Int!) {
        entries(section: "post", limit: 5, offset: $offset) {
          ... on post_post_Entry {
            title
            descriptor0
            categories {
              ...on blogCategories_Category {
                title
                slug
              }
            }
            heroImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            url
            postDate
            authors {
              ... on authors_authors_Entry {
                title
                slug
              }
            }
          }
        }
      }
    `;
    const url = process.env.GATSBY_CRAFT_API_URL;
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query,
        variables: { offset },
      }),
    };

    try {
      const newPosts = await fetch(url, params);
      const results = await newPosts.json();
      const p = await results.data.entries.map( (post, i, array) => {
        return {
          title: post.title,
          description: post.descriptor0,
          category: post.categories[0].slug,
          image: resolveImage(post.heroImage),
          author: post.authors[0].title,
          date: post.postDate,
          link: post.url,
          special: (i + 1 === array.length) && array.length === 1,
        };
      });

      if (p.length >= 1) {
        posts.push(...p);
        setPage(page + 1);
      }

      if (p.length < 5) {
        setDontGetPosts(true);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  useEffect(() => {}, [offset]);

  return (
    <Container variant="2xl">
      <div className="grid grid-cols-2 md:grid-cols-4 md:grid-rows-2 gap-6" aria-live="polite">
        {posts?.map((post, i) => {
          const size = i % 5 === 1 || post.special ? "lg" : "sm";
          const nudge = size === "lg" && post.special;

          return (
            <PostCard {...post} size={size} key={i} nudge={nudge} />
          )
        })}
      </div>

      <InView
        className="invisible"
        onEnter={() => {
          if (!dontGetPosts) getMorePosts();
        }}
      >
      </InView>
      

    </Container>
  );
};

PostsContainer.defaultProps = {};

PostsContainer.propTypes = {};

export default PostsContainer;