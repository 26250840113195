import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Knockout, Container, Button, Field } from "@atoms";
import axios from "axios";
import gsap from "gsap";

const ManifestoContact = ({ heading, subhead, button }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const errorMessage = useRef();
  const successMessage = useRef();

  useEffect(() => {
    if (success) {
      gsap.to(successMessage.current, {
        opacity: 1,
        duration: 0.2,
        display: "flex",
        // easing: "back",
      });
    }
  }, [success]);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const submitForm = () => {
    if (formData && !success) {
      axios
        .post(`/.netlify/functions/mailchimp`, JSON.stringify(formData), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then(res => {
          if (!res.data.error) {
            setSuccess(true);
            return;
          }
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          setError(
            "Looks like you're already signed up or there is a problem with your email address. Please check your submission and try again."
          );
        });
    } else {
      setError("Please fill out all required fields");
    }
  }
  return (
    <div className={classNames("max-w-xs w-full flex-1 border-3 border-bright-yellow py-6 flex justify-center transition-colors",
      { "bg-bright-yellow": success },
    )}>
      <div className="px-6">
        <div className="flex flex-col text-center">
          <div className="mb-3">
            <Knockout
              variant="h5"
              knockoutColor="bright-yellow"
              textColor="black"
            >
              {heading}
            </Knockout>
          </div>

          <Text variant="xs" className={classNames("mb-4 px-6 transition-colors",
            { "text-black": success },
          )}>
            {subhead}
          </Text>
        </div>

        <div className="flex flex-col text-center">
          <div
            ref={errorMessage}
            className="mb-2 h-0 overflow-hidden"
          >
            <div className="p-3">{error}</div>
          </div>

          <div
            ref={successMessage}
            className="mb-2 hidden"
          >
            <Text variant="sm" className="p-3 text-black">You have successfully subscribed to our insights.</Text>
          </div>

          <form
            className={classNames("transition-opacity",
              { "opacity-0 hidden": success },
            )}
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className="mb-4 space-y-4">
              <Field
                label="Name"
                name="name"
                placeholder="Name..."
                required={true}
                type="text"
                hideLabel
                onChange={e => {
                  setFormData({ ...formData, name: e.target.value });
                }}
              />
              <Field
                label="Email"
                name="email"
                placeholder="Email..."
                required={true}
                type="text"
                hideLabel
                onChange={e => {
                  setFormData({ ...formData, email: e.target.value });
                }}
              />
            </div>
            <Button
              arrowSize="sm"
              arrowColor="bright-yellow"
              border={false}
              underlineColor="transparent"
              type="submit"
            >
              <Text variant="sm" className="mr-3">{button}</Text>
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

ManifestoContact.defaultProps = {};
ManifestoContact.propTypes = {};

export default ManifestoContact;